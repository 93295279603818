import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { useFormik } from 'formik'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { BasicToast, ToastContext } from '@ubnt/ui-components'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import {
  fetchAddresses,
  selectFetchAddressIsLoading,
} from 'features/addresses/module/addresses'
import {
  addCard,
  resetAddCardErrors,
  selectCardsApiError,
  selectIsAddCardLoading,
  selectStripeCardError,
} from 'features/payment-methods/modules/addCard'
import { selectIsCustomerLoading } from 'features/stripe/modules/stripeCustomer'
import { StripeElements } from 'features/stripe/ui/StripeElements'
import {
  validStripeCountryMap,
  validStripeRegionMap,
} from 'features/stripe/ui/regions'
import { selectIsEmployeeAccount } from 'modules/profile'
import { sendWebKitMessage } from 'utils/sendWebKitMessage'
import Yup from 'validators/yupLocaleConfig'
import { ValidationError } from 'components/Input'
import { StripeCountryCode, StripeRegionCode } from 'features/stripe/ui/types'
import { usStripeCountries } from 'features/stripe/ui/countries/usStripeCountries'
import { regionsWithMultipleCountries } from 'features/stripe/config'
import { StripeRegionContext } from '../../stripe/ui/Region'
import { StyledModal, Wrapper } from './Components'
import { checkAddress, handleCaughtAddressError } from './utils'

import { CardAddressForm } from './forms/CardAddressForm'
import { CardInfoForm } from './forms/CardInfoForm'

interface Props {
  addPaymentView?: boolean
  subscriptionRegion?: StripeRegionCode
}

const AddPaymentMethodForm: React.FC<
  Props & ModalProps & WrappedComponentProps
> = ({ addPaymentView = false, isOpen, onClose, intl, subscriptionRegion }) => {
  const dispatch = useDispatch()
  const isCardLoading = useSelector(selectIsAddCardLoading)
  const isCustomerLoading = useSelector(selectIsCustomerLoading)
  const addressIsLoading = useSelector(selectFetchAddressIsLoading)
  const isLoading = isCardLoading || isCustomerLoading || addressIsLoading
  const apiError = useSelector(selectCardsApiError)
  const stripeError = useSelector(selectStripeCardError)
  const [createError, setCreateError] = useState<string | null>(null)
  const [addressError, setAddressError] = useState<{
    [key: string]: string | string[]
  } | null>(null)

  const stripe = useStripe()
  const elements = useElements()
  const { country, region } = useContext(StripeRegionContext)
  const isEmployee = useSelector(selectIsEmployeeAccount)
  const { createNotification, removeNotification } = useContext(ToastContext)

  const cardRegion = subscriptionRegion || region

  const selectedRegion = validStripeRegionMap.get(cardRegion)

  const userCountry = useMemo(() => {
    if (!selectedRegion?.code) {
      return null
    }
    // Since we default the region to be US when the user is in an unsupported stripe region we also need to confirm that the country the user
    // is in belongs to the US region. In cases where it does not, it means it's actually our default value and we should also default the country
    // to be the same as the region (the US)
    if (
      selectedRegion.code === StripeRegionCode.US &&
      !usStripeCountries.find((stripeCountry) => stripeCountry.code === country)
    ) {
      return selectedRegion.code
    }
    if (regionsWithMultipleCountries.includes(selectedRegion.code)) {
      return country
    }

    return selectedRegion.code
  }, [selectedRegion, country])

  useEffect(() => {
    dispatch(fetchAddresses())
  }, [dispatch])

  const {
    values,
    setSubmitting,
    touched,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    handleBlur,
    setValues,
    handleChange,
    errors,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      country: userCountry?.toUpperCase() || 'US',
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      cardCountry: '',
    },
    onSubmit: async (values) => {
      const { name, country, line1, line2, city, state, postal_code } = values
      try {
        await checkAddress(values)
      } catch (error) {
        handleCaughtAddressError(error, intl, setAddressError)
        return
      }
      if (!stripe || !elements) return
      const card = elements.getElement(CardNumberElement)

      if (!card) return
      const { source, error } = await stripe.createSource(card, {
        type: 'card',
        owner: {
          name,
          address: {
            country,
            line1,
            line2,
            city,
            state,
            postal_code,
          },
        },
      })

      if (
        source &&
        (source.card?.country?.toLowerCase() === userCountry || isEmployee)
      ) {
        dispatch(
          addCard(source.id, selectedRegion?.code || StripeRegionCode.US)
        )
      } else if (
        source?.card?.country &&
        !validStripeCountryMap.has(
          source.card.country.toLowerCase() as StripeCountryCode
        )
      ) {
        setCreateError(
          intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_INVALID_REGION' })
        )
      } else if (
        source &&
        source.card?.country?.toLowerCase() !== selectedRegion
      ) {
        setCreateError(
          intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_WRONG_REGION' })
        )
      } else if (error) {
        setCreateError(error.message || null)
      }
      setSubmitting(false)
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required().label('SETTINGS_PAYMENTS_NAME_ON_CARD'),
      country: Yup.string().required().label('COMMON_LABEL_COUNTRY'),
      line1: Yup.string().required().label('COMMON_LABEL_STREET_ADDRESS'),
      city: Yup.string().required().label('COMMON_LABEL_CITY'),
      state: Yup.string().required().label('COMMON_LABEL_STATE'),
      postal_code: Yup.string().required().label('COMMON_LABEL_POSTAL_CODE'),

      cardNumber: Yup.string()
        .required()
        .matches(/^complete$/, 'Your card number is invalid')
        .label('SETTINGS_PAYMENTS_LABEL_CARD_NUMBER'),
      cardExpiry: Yup.string()
        .required()
        .matches(/^complete$/, 'Your Exipration date is invalid')
        .label('SETTINGS_PAYMENTS_LABEL_EXPIRATION_DATE'),
      cardCvc: Yup.string()
        .required()
        .matches(/^complete$/, 'Your CVC is invalid')
        .label('SETTINGS_PAYMENTS_LABEL_SECURITY_CODE'),
    }),
  })

  useEffect(() => {
    setCreateError(null)
  }, [values])

  useEffect(() => {
    if (apiError === 'Duplicate card is not allowed.') {
      return setCreateError(
        intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_DUPLICATED_CARD' })
      )
    }
    if (apiError === 'Invalid card country.') {
      return setCreateError(
        intl.formatMessage(
          { id: 'STRIPE_PAYMENT_ERROR_UNSUPORTED_CARD' },
          {
            region: selectedRegion?.name,
          }
        )
      )
    }
    if (
      stripeError &&
      stripeError.includes(
        'Your card could not be authorized using the postal code provided.'
      )
    ) {
      return setCreateError(
        intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_POSTAL_CODE' })
      )
    }
    if (stripeError) {
      return setCreateError(stripeError)
    }
    if (apiError) {
      return setCreateError(intl.formatMessage({ id: 'GENERIC_ERROR_MESSAGE' }))
    }
    setCreateError(null)
  }, [apiError, selectedRegion?.name, intl, stripeError])

  const handleClose = useCallback(() => {
    if (addPaymentView) {
      sendWebKitMessage('cancel')
    } else {
      onClose?.()
      dispatch(resetAddCardErrors())
    }
  }, [addPaymentView, dispatch, onClose])

  useEffect(() => {
    if (createError) {
      createNotification(
        <BasicToast
          stateIndicator="danger"
          title={<FormattedMessage id="GENERIC_ERROR_MESSAGE" />}
          details={createError}
        />
      )
    }
  }, [createError, createNotification, removeNotification])

  const isButtonDisabled = useMemo(() => {
    const hasAddressError =
      !!addressError && !!Object.values(addressError).length
    const hasFormikError = !!Object.values(errors).length
    const hasAnyError = hasAddressError || hasFormikError

    if (isLoading || isSubmitting || hasAnyError) {
      return true
    }
    return false
  }, [addressError, errors, isSubmitting, isLoading])

  return (
    <StyledModal
      size="small"
      isOpen={isOpen}
      onRequestClose={() => handleClose()}
      appView={addPaymentView ? true : false}
      title={
        <FormattedMessage
          id="SETTINGS_PAYMENTS_MODAL_TITLE_ADD"
          tagName="div"
        />
      }
      actions={[
        {
          text: addPaymentView
            ? intl.formatMessage({ id: 'COMMON_ACTION_BACK' })
            : intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          onClick: () => handleClose(),
          variant: 'default',
          disabled: isLoading || isSubmitting,
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_ADD' }),
          type: 'submit',
          variant: 'primary',
          onClick: handleSubmit as any,
          disabled: isButtonDisabled,
          loader: isOpen && (isLoading || isSubmitting) ? 'spinner' : undefined,
          style: { outline: 'none' },
        },
      ]}
    >
      <Wrapper>
        <CardInfoForm
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={touched}
          errors={errors}
          cardRegion={cardRegion}
          subscriptionRegion={subscriptionRegion}
          createError={createError}
          resetCreateError={() => setCreateError(null)}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
        <CardAddressForm
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setValues={setValues as any}
          touched={touched}
          errors={errors}
          setFieldTouched={setFieldTouched}
          submitError={createError}
          addressError={addressError}
          setAddressError={setAddressError}
          country={userCountry}
          cardRegion={cardRegion}
        />
      </Wrapper>
      {addressError?.['detail'] ? (
        <ValidationError>
          <FormattedMessage id="SETTINGS_PAYMENTS_ADDRESS_VALIDATION_FAILED" />
        </ValidationError>
      ) : null}
    </StyledModal>
  )
}

const AddPaymentMethodModalConnected = injectIntl(AddPaymentMethodForm)

export const ADD_PAYMENT_METHOD_MODAL_ID = 'ADD_PAYMENT_METHOD_MODAL_ID'

const StripeWrappedModal: React.FC<Props & ModalProps> = (props) => {
  const { region } = useContext(StripeRegionContext)
  const selectedRegion = props.subscriptionRegion
    ? props.subscriptionRegion
    : region
  return (
    <StripeElements region={selectedRegion}>
      <AddPaymentMethodModalConnected {...props} />
    </StripeElements>
  )
}

export default () => (
  <ModalWrapper modalId={ADD_PAYMENT_METHOD_MODAL_ID}>
    <StripeWrappedModal />
  </ModalWrapper>
)
