import { createSelector } from 'reselect'
import type Stripe from 'stripe'

import { StripeRegionCode } from 'features/stripe/ui/types'
import createDataModule from 'utils/moduleCreator'
import { UiTaxId } from './types'

const {
  api: { billing },
} = __CONFIG__

export type UiTaxIdWithRegion = Record<
  StripeRegionCode,
  Stripe.ApiList<UiTaxId>
>

export const uiTaxIdDataKey = 'uiTaxIdDataKey'

const getUrl = (url: string, region: string) => `${url}?region=${region}`

export const dataModule = createDataModule<UiTaxIdWithRegion>(
  uiTaxIdDataKey,
  billing.paths.uiTaxId,
  billing.base,
  undefined,
  getUrl
)

export const {
  fetch: fetchUiTaxIds,
  selectIsLoading: selectIsUiTaxIdsLoading,
  reducer: uiTaxIdsReducer,
  rootSaga: uiTaxIdsRootSaga,
} = dataModule

export const selectUiTaxIds = createSelector(
  dataModule.selectData,
  (uiTaxIds) => {
    const region = Object.keys(uiTaxIds)[0] as StripeRegionCode
    const uiRegionTaxIds = uiTaxIds[region]?.data?.map(({ value }) => value)

    return uiRegionTaxIds
  }
)
