import { createSelector } from 'reselect'

import createDataModule, { defaultInitialState } from 'utils/moduleCreator'
import { StripeRegionCode } from 'features/stripe/ui/types'

import { Invoices, InvoicesUpcoming } from './types'

const INVOICES_DEFAULTS = {
  data: [],
  meta: { has_more: false, next_starting_after: '' },
}

const {
  api: { billing },
} = __CONFIG__

export const dataKey = 'invoicesUpcoming'

const getUrl = (
  url: string,
  item: { id?: string; region: StripeRegionCode }
) =>
  item && item.id
    ? `${url}?subscriptionId=${item.id}&region=${item.region}`
    : url

const mergeFn = (data: InvoicesUpcoming, prevData: Invoices) => {
  try {
    const regions = Object.keys(prevData) as StripeRegionCode[]
    regions.forEach((region) => {
      if (data[region as keyof Invoices]) {
        prevData[region as keyof Invoices].data.push(
          data[region as keyof Invoices]
        )
      }
    })
    return prevData
  } catch (e) {
    console.error(e)
  }
}

const initialState = {
  ...defaultInitialState,
  data: {
    us: INVOICES_DEFAULTS,
    ca: INVOICES_DEFAULTS,
    gb: INVOICES_DEFAULTS,
    eu: INVOICES_DEFAULTS,
    au: INVOICES_DEFAULTS,
    ae: INVOICES_DEFAULTS,
    sg: INVOICES_DEFAULTS,
    jp: INVOICES_DEFAULTS,
    row: INVOICES_DEFAULTS,
  },
}

export const dataModule = createDataModule<Invoices>(
  dataKey,
  billing.paths.invoicesUpcoming,
  billing.base,
  initialState,
  getUrl,
  mergeFn
)

export const {
  mergeFetch: fetchUpcomingInvoice,
  reducer: invoicesUpcomingReducer,
  rootSaga: invoicesUpcomingRootSaga,
} = dataModule

export const selectUpcomingInvoices = createSelector(
  dataModule.selectData,
  (upcomingInvoicesData) => {
    const invoiceRegions = Object.keys(
      upcomingInvoicesData
    ) as StripeRegionCode[]

    if (!invoiceRegions.length) {
      return []
    }

    const userUpcomingInvoices = invoiceRegions
      .map((region) => {
        return (
          upcomingInvoicesData[region as keyof Invoices]?.data.map(
            (invoice) => {
              return { ...invoice, region }
            }
          ) ?? []
        )
      })
      .flat()

    return userUpcomingInvoices
  }
)
