import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { unionBy } from 'lodash-es'

import createDataModule, { defaultInitialState } from 'utils/moduleCreator'
import { ReduxAction } from 'types/types'
import { StripeRegionCode } from 'features/stripe/ui/types'

import { fetchUpcomingInvoice } from './invoicesUpcoming'
import { CustomerSubscriptions, SubscriptionType, Subscriptions } from './types'

const {
  api: { billing },
} = __CONFIG__

const mergeFn = (data: CustomerSubscriptions, prevData: Subscriptions) => {
  const newData: Subscriptions = {
    [StripeRegionCode.US]: [],
    [StripeRegionCode.CA]: [],
    [StripeRegionCode.GB]: [],
    [StripeRegionCode.EU]: [],
    [StripeRegionCode.AU]: [],
    [StripeRegionCode.AE]: [],
    [StripeRegionCode.SG]: [],
    [StripeRegionCode.JP]: [],
    [StripeRegionCode.ROW]: [],
  }
  try {
    const regions = Object.keys(prevData) as StripeRegionCode[]
    regions.forEach((region) => {
      newData[`${region as keyof Subscriptions}`] = unionBy(
        data[`${region as keyof CustomerSubscriptions}`]?.data ?? [],
        prevData[`${region as keyof CustomerSubscriptions}`],
        'id'
      )
    })
    return newData
  } catch (e) {
    console.error(e)
  }
}

const getUrl = (
  url: string,
  { starting_after }: { starting_after?: string } = {}
) => {
  const qs = `?status=all&limit=100${
    starting_after ? `&starting_after=${starting_after}` : ''
  }`
  return `${url}${qs}`
}

export const dataKey = 'subscriptions'

export const dataModule = createDataModule<Subscriptions>(
  dataKey,
  billing.paths.subscriptions,
  billing.base,
  {
    ...defaultInitialState,
    data: {
      [StripeRegionCode.US]: [],
      [StripeRegionCode.CA]: [],
      [StripeRegionCode.GB]: [],
      [StripeRegionCode.EU]: [],
      [StripeRegionCode.AU]: [],
      [StripeRegionCode.AE]: [],
      [StripeRegionCode.SG]: [],
      [StripeRegionCode.JP]: [],
      [StripeRegionCode.ROW]: [],
    },
  },
  getUrl,
  mergeFn
)

export const {
  fetchAll: fetchSubscriptions,
  selectIsFetchLoading: selectIsSubscriptionsFetching,
  selectData: selectUnparsedSubscriptions,
  setData: setSubscriptionsData,

  FETCH_ALL_DONE: SUBSCRIPTION_FETCH_ALL_DONE,

  reducer: subscriptionsReducer,
} = dataModule

export const selectSubscriptions = createSelector(
  selectUnparsedSubscriptions,
  (subscriptionsData) => {
    const subscriptionRegions = Object.keys(
      subscriptionsData
    ) as StripeRegionCode[]
    if (!subscriptionRegions.length) {
      return []
    }

    const userSubscriptions = subscriptionRegions
      .map((region) => {
        return (
          subscriptionsData[region as keyof Subscriptions]?.map(
            (subscription) => {
              return { ...subscription, region }
            }
          ) ?? []
        )
      })
      .flat()

    return userSubscriptions
  }
)

export const selectActiveSubscriptions = createSelector(
  selectSubscriptions,
  (subscriptions) => subscriptions.filter(({ status }) => status !== 'canceled')
)

function* fetchRelatedSaga(action: ReduxAction<CustomerSubscriptions>) {
  const { payload } = action
  if (!payload) return

  const subscriptionRegions = Object.keys(payload) as StripeRegionCode[]

  const subscriptions: SubscriptionType[] = subscriptionRegions
    .map((region) => {
      return (
        payload[region]?.data.map((subscription) => {
          return { ...subscription, region: region as StripeRegionCode }
        }) ?? []
      )
    })
    .flat()

  for (let i = 0; i < subscriptions?.length; i++) {
    if (
      subscriptions[i].status === 'active' ||
      subscriptions[i].status === 'trialing'
    ) {
      yield put(fetchUpcomingInvoice(subscriptions[i]))
    }
  }
}

export function* subscribeToFetchDoneSaga() {
  yield takeEvery(SUBSCRIPTION_FETCH_ALL_DONE, fetchRelatedSaga)
}

export function* subscriptionsRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToFetchDoneSaga)])
}
