import { useDispatch } from 'react-redux'

import { fetchCcdTickets } from 'modules/zendesk/ccdUserTickets'
import { fetchClosedTickets } from 'modules/zendesk/closedUserTickets'
import { fetchOpenTickets } from 'modules/zendesk/openUserTickets'

export const useFetchAll = () => {
  const dispatch = useDispatch()

  const fetchAllTickets = () => {
    dispatch(fetchOpenTickets())
    dispatch(fetchClosedTickets())
    dispatch(fetchCcdTickets())
  }

  return {
    fetchAllTickets,
  }
}
