import { auStripeCountries } from './countries/auStripeCountries'
import { caStripeCountries } from './countries/caStripeCountries'
import { euStripeCountries } from './countries/euStripeCountries'
import { gbStripeCountries } from './countries/gbStripeCountries'
import { rowStripeCountries } from './countries/rowStripeCountries'
import { usStripeCountries } from './countries/usStripeCountries'
import {
  StripeCountry,
  StripeCountryCode,
  StripeRegion,
  StripeRegionCode,
} from './types'

const {
  featureFlags: { isJpRegionEnabled },
} = __CONFIG__

const stripeRegionsWithFeatFlag = [
  {
    code: StripeRegionCode.JP,
    name: 'Japan',
  },
] as const satisfies Readonly<StripeRegion[]>

export const validStripeRegions = [
  {
    code: StripeRegionCode.US,
    name: 'United States',
  },
  {
    code: StripeRegionCode.CA,
    name: 'Canada',
  },
  {
    code: StripeRegionCode.GB,
    name: 'United Kingdom',
  },
  {
    code: StripeRegionCode.EU,
    name: 'Europe',
  },
  {
    code: StripeRegionCode.AU,
    name: 'Australia',
  },
  {
    code: StripeRegionCode.SG,
    name: 'Singapore',
  },
  {
    code: StripeRegionCode.AE,
    name: 'United Arab Emirates',
  },
  {
    code: StripeRegionCode.ROW,
    name: 'Global',
  },
  ...(isJpRegionEnabled ? stripeRegionsWithFeatFlag : []),
] as const satisfies Readonly<StripeRegion[]>

export const validStripeRegionMap = new Map<StripeRegionCode, StripeRegion>(
  validStripeRegions.map((x) => [x.code, x])
)

const stripeCountriesWithRegionFeatFlag = [
  {
    code: 'jp',
    name: 'Japan',
    region: StripeRegionCode.JP,
  },
] as const satisfies Readonly<StripeCountry[]>

export const validStripeCountries = [
  ...euStripeCountries,
  ...gbStripeCountries,
  ...auStripeCountries,
  ...usStripeCountries,
  ...caStripeCountries,
  ...rowStripeCountries,
  {
    code: 'sg',
    name: 'Singapore',
    region: StripeRegionCode.SG,
  },
  {
    code: 'ae',
    name: 'United Arab Emirates',
    region: StripeRegionCode.AE,
  },
  ...(isJpRegionEnabled ? stripeCountriesWithRegionFeatFlag : []),
] as const satisfies Readonly<StripeCountry[]>

export const validStripeCountryMap = new Map<StripeCountryCode, StripeCountry>(
  validStripeCountries.map((x) => [x.code, x])
)
