import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormikErrors, FormikTouched } from 'formik'
import { Dropdown, Input } from '@ubnt/ui-components'
import { useAutocomplete } from 'utils/useAutocomplete'
import { useOutsideClick } from 'utils/useOutsideClick'
import { getFormattedAddress } from 'utils/getFormattedAddress'
import {
  validStripeCountries,
  validStripeCountryMap,
  validStripeRegionMap,
} from 'features/stripe/ui/regions'
import { RoundFlag } from 'components/RoundFlag'
import {
  AddressResult,
  AddressResultsContainer,
  AddressResultsInnerContainer,
  InputWrapper,
  Title,
} from '../Components'
import { regionsWithMultipleCountries } from 'features/stripe/config'
import { StripeCountryCode, StripeRegionCode } from 'features/stripe/ui/types'
import { useDebouncedCallback } from 'use-debounce'
import { checkAddress, handleCaughtAddressError } from '../utils'

export type AddCardValues = {
  name: string
  country: string
  line1: string
  line2: string
  city: string
  state: string
  postal_code: string
  cardNumber: string
  cardExpiry: string
  cardCvc: string
  cardCountry: string
}

export type EditCardValues = {
  name: string
  country: string
  line1: string
  line2: string
  city: string
  state: string
  postal_code: string
  exp_month: string
  exp_year?: string
}

export type CardValues = AddCardValues | EditCardValues

interface FormProps {
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  values: CardValues
  setValues: (
    values: React.SetStateAction<CardValues>,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<CardValues>>
  touched: FormikTouched<CardValues>
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean
  ) => Promise<FormikErrors<CardValues>> | Promise<void>
  errors: FormikErrors<CardValues>
  submitError: string | null
  addressError: {
    [key: string]: string | string[]
  } | null
  setAddressError: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string | string[]
    } | null>
  >
  cardRegion: StripeRegionCode
  country?: string | null
  isEditPaymentMethod?: boolean
}

export const CardAddressForm: React.FC<FormProps> = ({
  handleBlur,
  values,
  setValues,
  handleChange,
  touched,
  errors,
  setFieldTouched,
  submitError,
  addressError,
  setAddressError,
  cardRegion,
  country,
  isEditPaymentMethod = false,
}) => {
  const [addressResultsVisible, setAddressResultsVisible] = useState(false)
  const [active, setActive] = useState<number>(0)
  const [cardCountry, setCardCountry] = useState<string | null | undefined>(
    country
  )
  const [addressFocused, setAddressFocused] = useState(false)
  const intl = useIntl()
  const wrapperRef = useRef(null)

  const selectedRegion = cardRegion
    ? validStripeRegionMap.get(cardRegion)
    : undefined

  const selectedCountry = validStripeCountryMap.get(
    values.country.toLowerCase() as StripeCountryCode
  )

  const selectedCountryCode = isEditPaymentMethod
    ? selectedCountry?.code
    : cardCountry

  useOutsideClick(wrapperRef, () => {
    setAddressResultsVisible(false)
    setActive(0)
  })

  const { placesService, placePredictions, getPlacePredictions } =
    useAutocomplete()

  useEffect(() => {
    if (placePredictions?.length && values.country === 'US') {
      setAddressResultsVisible(true)
    } else {
      setAddressResultsVisible(false)
      setActive(0)
    }
  }, [values.country, placePredictions])

  const handleAddressSelected = useCallback(
    (addressDetails: google.maps.places.PlaceResult) => {
      setValues({
        ...values,
        ...getFormattedAddress(addressDetails),
      })
      setFieldTouched('postal_code', false)
    },
    [setFieldTouched, setValues, values]
  )

  const handleKeyDown = useCallback(
    (event) => {
      if (!event?.key) {
        return
      } else if (event.key === 'Tab') {
        setAddressResultsVisible(false)
        setActive(0)
      } else if (event.key === 'Enter') {
        event.preventDefault()
        setAddressResultsVisible(false)
        setActive(0)
        placesService?.getDetails(
          {
            placeId: placePredictions[active]?.place_id,
            fields: ['address_component'],
          },
          (addressDetails) => {
            addressDetails && handleAddressSelected(addressDetails)
          }
        )
      } else if (event.key === 'ArrowDown') {
        setActive(
          active < placePredictions.length - 1
            ? active + 1
            : placePredictions.length - 1
        )
      } else if (event.key === 'ArrowUp') {
        setActive(active > 0 ? active - 1 : 0)
      }
    },
    [active, handleAddressSelected, placePredictions, placesService]
  )

  const checkAddressDebounced = useDebouncedCallback(async () => {
    try {
      await checkAddress(values)
      setAddressError(null)
    } catch (error) {
      handleCaughtAddressError(error, intl, setAddressError)
    }
  }, 200)

  useEffect(() => {
    if (addressFocused) {
      checkAddressDebounced()
    }
  }, [checkAddressDebounced, addressFocused, values])

  const hasRegionMultipleCountries = useMemo(() => {
    if (!selectedRegion?.code || (!cardCountry && !selectedCountry)) {
      return false
    }
    return regionsWithMultipleCountries.includes(selectedRegion?.code)
  }, [selectedRegion?.code, cardCountry, selectedCountry])

  return (
    <>
      <Title>
        <FormattedMessage id="SETTINGS_PAYMENTS_BILLING_ADDRESS" />
      </Title>
      {hasRegionMultipleCountries && selectedCountryCode && (
        <InputWrapper>
          <Dropdown
            label="Country"
            value={selectedCountryCode}
            onChange={(option) => {
              !isEditPaymentMethod && setCardCountry(option.value.toString())
              setValues({
                ...values,
                country: option.value.toUpperCase(),
              })
            }}
            options={validStripeCountries
              .filter((country) => country.region === selectedRegion?.code)
              .map((country) => ({
                label: country.name,
                value: country.code,
                image: <RoundFlag countryCode={country.code} />,
              }))}
            searchable
            variant="secondary"
            minHeight="214px"
            width="100%"
            optionContainerClassName="change-region__dropdown"
            disabled={isEditPaymentMethod}
          />
        </InputWrapper>
      )}
      <InputWrapper>
        <Input
          onKeyDown={handleKeyDown}
          name="line1"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_STREET_ADDRESS" />}
          onBlur={handleBlur}
          onChange={(e) => {
            values.country === 'US' &&
              getPlacePredictions({ input: e.target.value })
            handleChange(e)
          }}
          invalid={touched.line1 && (errors.line1 || addressError?.['line1'])}
          value={values.line1}
          autoComplete="new-line1"
          full
        />
        <AddressResultsContainer
          ref={wrapperRef}
          visible={addressResultsVisible}
        >
          <AddressResultsInnerContainer>
            {placePredictions?.length &&
              placePredictions.map(
                (
                  place: google.maps.places.AutocompletePrediction,
                  i: number
                ) => (
                  <AddressResult
                    active={active === i}
                    key={i.toString()}
                    onClick={() => {
                      setAddressResultsVisible(false)
                      setActive(0)
                      placesService?.getDetails(
                        {
                          placeId: place?.place_id,
                          fields: ['address_component'],
                        },
                        (addressDetails) => {
                          addressDetails &&
                            handleAddressSelected(addressDetails)
                        }
                      )
                    }}
                  >
                    {place?.description}
                  </AddressResult>
                )
              )}
          </AddressResultsInnerContainer>
        </AddressResultsContainer>
      </InputWrapper>
      <InputWrapper>
        <Input
          name="city"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_CITY" />}
          onBlur={handleBlur}
          onChange={handleChange}
          invalid={touched.city && (errors.city || addressError?.['city'])}
          value={values.city}
          autoComplete="new-city"
          full
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          name="state"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_STATE" />}
          onBlur={handleBlur}
          onChange={handleChange}
          invalid={touched.state && (errors.state || addressError?.['state'])}
          value={values.state}
          autoComplete="new-state"
          onFocus={() => setAddressFocused(true)}
          full
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          name="postal_code"
          variant="secondary"
          label={<FormattedMessage id="COMMON_LABEL_POSTAL_CODE" />}
          onBlur={handleBlur}
          onChange={handleChange}
          invalid={
            (touched.postal_code &&
              (errors.postal_code || addressError?.['postal_code'])) ||
            !!submitError
          }
          value={values.postal_code}
          autoComplete="new-postal-code"
          onFocus={() => setAddressFocused(true)}
          full
        />
      </InputWrapper>
    </>
  )
}
