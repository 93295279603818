import React, { SetStateAction, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { difference } from 'lodash-es'
import { useDispatch, useSelector } from 'react-redux'
import { api } from 'api'

import { Form } from 'components/support/Wrappers'
import { Bubble } from 'components/support/Bubble'
import { SendSafelyDropzone } from 'components/support/SendSafelyDropzone'
import { SupportFile } from 'components/support/SupportFile'
import { Footer } from 'components/support/Footer'
import useGetDevices from 'components/support/useGetDevices'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'

import { fetchOpenTickets } from 'modules/zendesk/openUserTickets'
import { DESCRIPTION_MAX_LENGTH, DESCRIPTION_MIN_LENGTH } from '../constants'
import { useSendSafely } from '../useSendSafely'
import { handleCreateRequestError } from '../utils'
import { useFormatChatMessage } from './utils/useFormatChatMessage'
import { useFormatTicket } from './utils/useFormatTicket'
import { UniFiFormValidationSchema } from './config/uniFiFormValidationSchema'
import { getSupportFile } from './utils/getSupportFile'
import { renderUniFiSection } from './utils/nextUniFiSection'
import { getConfig } from './config/formConfig'
import { FormSections, FormValue, SupportFormState } from './types'
import {
  Assistance,
  Console,
  Device,
  Model,
  TalkVip,
  UniFiStateValues,
} from './config/types'
import { getIsChatOpen } from './utils/getIsChatOpen'
import { createOrUpdateZendeskUser } from './utils/createOrUpdateZendeskUser'

type Props = {
  dropzoneId: string
  setIsFormSubmitted: React.Dispatch<SetStateAction<boolean>>
  followUpTicketId?: string
}

export const sessionStorageChatKey = 'isInChat'

export const UniFiForm: React.FC<Props> = ({
  dropzoneId,
  setIsFormSubmitted,
  followUpTicketId,
}) => {
  const { consoles } = useGetDevices()

  const config = getConfig(consoles)

  const [visibleSections, setVisibleSections] = useState<FormSections[]>([
    UniFiStateValues.ASSISTANCE,
  ])
  const sessionStorageChatInfo = sessionStorage.getItem(sessionStorageChatKey)
  const [isInActiveChat, setIsInActiveChat] = useState(
    sessionStorageChatInfo === 'true' ? true : false
  )
  const [isSupportFileAllowed, setIsSupportFileAllowed] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false)

  const { handleSendSafelyUpload } = useSendSafely(
    dropzoneId,
    setIsAttachmentUploading
  )
  const { formatChatMessage } = useFormatChatMessage(config)
  const { formatRequestedTicket } = useFormatTicket(config)
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)
  const dispatch = useDispatch()

  const chatElement = document.querySelector('#bubble-btn')

  useEffect(() => {
    const chatWidgetScript = document.createElement('script')
    if (isDoneAuthenticating) {
      chatWidgetScript.src = '/scripts/widget.js'
      chatWidgetScript.async = true
      document.body.appendChild(chatWidgetScript)
    }

    if (document.body.contains(chatWidgetScript)) {
      document.body.removeChild(chatWidgetScript)
    }
  }, [isDoneAuthenticating])

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    isSubmitting,
    resetForm,
  } = useFormik<SupportFormState>({
    initialValues: {},
    validationSchema: UniFiFormValidationSchema,
    onSubmit: async (values) => {
      try {
        const attachmentsUrl = await handleSendSafelyUpload()
        const formattedRequest = await formatRequestedTicket(
          values,
          isSupportFileAllowed,
          selectedConsole,
          attachmentsUrl,
          followUpTicketId
        )

        if (!formattedRequest) return

        try {
          await api.createZendeskRequest(formattedRequest)
          setIsFormSubmitted(true)
        } catch (error) {
          handleCreateRequestError(
            error,
            setErrorMessage,
            formattedRequest,
            setIsFormSubmitted
          )
        }
      } catch (error) {
        console.error(error)
        setErrorMessage('SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION')
      }
    },
  })

  const selectedConsole = useMemo(
    () =>
      consoles?.find(
        (console) =>
          console.shadow?.state?.reported?.mac === values.console?.mac
      ),
    [consoles, values.console?.mac]
  )

  useEffect(() => {
    const closeChatEvent = () => {
      dispatch(fetchOpenTickets())
      setIsInActiveChat(false)
      sessionStorage.setItem(sessionStorageChatKey, 'false')
      resetForm()
      setVisibleSections([UniFiStateValues.ASSISTANCE])
    }

    document.addEventListener('closeChat', closeChatEvent)

    return () => {
      document.removeEventListener('closeChat', closeChatEvent)
    }
  }, [dispatch, resetForm])

  useEffect(() => {
    if (chatElement) {
      if (isInActiveChat && chatElement.classList.contains('hidden')) {
        chatElement.classList.remove('hidden')
      }
      if (!isInActiveChat && !chatElement.classList.contains('hidden')) {
        chatElement.classList.add('hidden')
      }
    }
  }, [chatElement, isInActiveChat, sessionStorageChatInfo])

  const clearState = (updatedSections: string[]) => {
    const existingStateValues = Object.keys(values)
    difference(existingStateValues, updatedSections).forEach((key) => {
      if (key === UniFiStateValues.SUPPORT_FILE) {
        return
      }
      return setFieldValue(key, undefined)
    })
  }

  const handleVisibilities = (
    clickedSection: FormSections,
    hasValue = true,
    nextSection?: FormSections
  ) => {
    if (
      visibleSections.includes(clickedSection) &&
      visibleSections[visibleSections.length - 1] !== clickedSection
    ) {
      const indexToRemove = visibleSections.indexOf(clickedSection)
      const updatedArray = visibleSections.slice(0, indexToRemove + 1)
      clearState(updatedArray)
      return hasValue && nextSection
        ? setVisibleSections([...updatedArray, nextSection])
        : setVisibleSections(updatedArray)
    }
    if (!nextSection) return
    return setVisibleSections((prev) => [...prev, nextSection])
  }

  const handleChange = (
    clickedSection: FormSections,
    value: FormValue,
    sectionToRender?: FormSections
  ) => {
    setFieldValue(clickedSection, value)
    handleVisibilities(clickedSection, value !== undefined, sectionToRender)
  }

  const isChatWorkingHours = getIsChatOpen()

  const isFormValid =
    !isSubmitting &&
    !Object.keys(errors).length &&
    !!values.description &&
    values.description.length > DESCRIPTION_MIN_LENGTH &&
    values.description.length < DESCRIPTION_MAX_LENGTH

  const sendChatMessage = async () => {
    const isChatOpen = getIsChatOpen()
    if (!isChatOpen) {
      return setErrorMessage('SUPPORT_CHAT_ERROR')
    }
    await createOrUpdateZendeskUser()
    setIsInActiveChat(true)
    sessionStorage.setItem(sessionStorageChatKey, 'true')
    const attachmentsUrl = await handleSendSafelyUpload()
    formatChatMessage(
      values,
      visibleSections,
      isSupportFileAllowed,
      selectedConsole,
      attachmentsUrl
    )
  }

  useEffect(() => {
    const getSupportFileUrl = async () => {
      const { downloadUrl } = await getSupportFile(selectedConsole)
      setFieldValue(UniFiStateValues.SUPPORT_FILE, downloadUrl)
    }

    if (selectedConsole) {
      getSupportFileUrl()
    }
  }, [selectedConsole, setFieldValue])

  const hasChatSupport = useMemo(() => {
    const isTalkVip = values.talkVip === TalkVip.vip
    const isUniFiPlayDevice = values.device === Device.playDevice
    const isUniFiPlayConsole = values.console?.id === Console.unifiPlayDevice
    const isUniFiPlayModel = values.model === Model.unifiPlayDevice

    if (
      isTalkVip ||
      isUniFiPlayModel ||
      isUniFiPlayConsole ||
      isUniFiPlayDevice
    ) {
      return false
    }
    return true
  }, [values.console?.id, values.device, values.model, values.talkVip])

  return (
    <Form onSubmit={handleSubmit}>
      {visibleSections.map((section, i) =>
        renderUniFiSection(section, {
          config,
          values,
          handleChange,
          shouldScroll: i + 1 === visibleSections.length && i > 0,
        })
      )}
      <Bubble
        question={config.attachments.fieldTitle}
        isVisible={visibleSections.includes(UniFiStateValues.DESCRIPTION)}
        extraText
      >
        <SendSafelyDropzone id={dropzoneId} />
        {!!values.supportFile && (
          <SupportFile
            supportFileDevice={selectedConsole?.shadow.state.reported.name}
            isSupportFileAllowed={isSupportFileAllowed}
            setIsSupportFileAllowed={setIsSupportFileAllowed}
          />
        )}
      </Bubble>
      {values.assistance !== Assistance.featureConfig && (
        <Footer
          isFormLoading={isSubmitting}
          startChat={
            isChatWorkingHours && hasChatSupport ? sendChatMessage : undefined
          }
          isFormValid={isFormValid}
          isInActiveChat={isInActiveChat}
          errorMessage={errorMessage}
          hideButton={isChatWorkingHours && hasChatSupport}
          isAttachmentUploading={isAttachmentUploading}
        />
      )}
    </Form>
  )
}
