import type { Stripe } from 'stripe'
import { FullSubscription } from './fullSubscriptions/fullSubscriptions'
import { StripeInvoice } from './module/types'

export const showSubscriptionFailedPaymentButtons = (
  fullSubscription: FullSubscription
) =>
  (!fullSubscription.isInProgress && fullSubscription.isFailed) ||
  (!fullSubscription.default_source && !fullSubscription.default_payment_method)

export const getProductIdFromInvoice = (invoice: StripeInvoice) => {
  const item = invoice.lines.data[invoice.lines.data.length - 1]
  if (item.plan?.product) {
    const productId =
      item.plan.product && typeof item.plan.product === 'string'
        ? item.plan.product
        : (item.plan.product as Stripe.Product | Stripe.DeletedProduct).id

    return productId
  }

  return item.id
}
