import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { PdfFileIcon } from '@ubnt/icons'
import SkeletonLoader from '@ubnt/ui-components/SkeletonLoader/SkeletonLoader'
import Table from '@ubnt/ui-components/SkeletonLoader/Skeleton/Table'

import styled from 'theme/styled'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { getCleanInvoiceLines } from 'features/invoices/ui/utils'
import { InvoicePaymentsSection } from 'features/invoices/ui/InvoicePaymentsSection'
import InvoiceHeader from 'features/invoices/ui/InvoiceHeader'
import InvoiceItemsTable from 'features/invoices/ui/InvoiceItemsTable'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { FlexWrapper } from 'components/SharedComponents'
import { motifPalette } from 'theme'

import {
  resetInvoiceLinesData,
  selectInvoiceLines,
  selectIsInvoiceLinesLoading,
} from '../module/invoiceLines'
import { selectInvoice } from '../module/invoices'
import { fetchUiTaxIds } from '../module/uiTaxIds'
import { fetchBusinessProfile } from '../module/businessProfile'
import { useCreateInvoicePDF } from './hooks/useCreateInvoicePDF'

interface Props extends ModalProps {
  invoiceId?: string
  setInvoiceForModal: (id?: string) => void
}

const {
  api: { accountBE },
} = __CONFIG__

const InvoiceModal: React.FC<Props> = ({
  isOpen,
  onClose,
  invoiceId,
  setInvoiceForModal,
}) => {
  const invoice = useSelector(selectInvoice(invoiceId ?? ''))
  const invoiceExtraLines = useSelector(selectInvoiceLines)
  const isFetchingMore = useSelector(selectIsInvoiceLinesLoading)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUiTaxIds(invoice?.region))
    dispatch(fetchBusinessProfile(invoice?.region))
  }, [dispatch, invoice?.region])

  const { createPDFLoading } = useCreateInvoicePDF(invoiceId, invoice?.region)

  const invoiceLinesData = useMemo(() => {
    if (invoiceExtraLines.length && invoice?.lines?.has_more) {
      return invoiceExtraLines
    }

    if (invoice) {
      return invoice.lines.data
    }
  }, [invoice, invoiceExtraLines])

  useEffect(() => {
    if (isFetchingMore) {
      setIsLoading(true)
    }

    if (!isFetchingMore) {
      setIsLoading(false)
    }

    if (invoice && !invoice.lines.has_more && isLoading) {
      setIsLoading(false)
    }
  }, [invoice, invoiceExtraLines, isFetchingMore, isLoading])

  if (!invoiceId || !invoice || !invoiceLinesData) {
    return null
  }

  const invoiceItems = getCleanInvoiceLines(invoiceLinesData)

  const handleCloseModal = () => {
    setInvoiceForModal()
    dispatch(resetInvoiceLinesData())
    onClose?.()
  }

  return (
    <GenericModal
      isOpen={isOpen}
      title={<FormattedMessage id="COMMON_ACTION_PREVIEW" />}
      headerBorderBottom
      size="large"
      height="large"
      isInvoiceModal
      onRequestClose={handleCloseModal}
      actions={[
        {
          text: (
            <StyledAnchorTag
              target="_blank"
              rel="noopener noreferrer"
              href={`${accountBE.base}/${
                accountBE.paths.billingInvoice
              }/${invoiceId}/${invoice?.region?.toUpperCase()}`}
            >
              <StyledPdfFileIcon />
              <FormattedMessage id="COMMON_ACTION_DOWNLOAD" />
            </StyledAnchorTag>
          ),
          variant: 'primary',
          disabled: isLoading,
          style: { height: '32px' },
          loader: createPDFLoading ? 'dots' : undefined,
        },
      ]}
      headerClassName="invoice-header"
      contentClassName="invoice-content"
    >
      <Wrapper>
        <InvoiceHeader
          invoice={invoice}
          siteName={invoiceLinesData?.[0]?.metadata?.deviceName || ''}
        />
        <FlexWrapper column padding={20}>
          {isLoading ? (
            <SkeletonLoader>
              <Table rowCounts={6} colCounts={4} rowHeight={64} />
            </SkeletonLoader>
          ) : (
            <InvoiceItemsTable
              items={invoiceItems}
              discount={invoice.discount}
              discountAmount={invoice.total_discount_amounts?.[0]?.amount}
              total={invoice.total}
              currency={invoice.currency}
              metadata={invoice.metadata}
              lines={invoice.lines.data}
            />
          )}
          <InvoicePaymentsSection invoice={invoice} />
        </FlexWrapper>
      </Wrapper>
    </GenericModal>
  )
}

export const INVOICE_MODAL_ID = 'INVOICE_MODAL_ID'

const WrappedInvoiceModal: React.FC<{
  invoiceId?: string
  setInvoiceForModal: (id?: string) => void
}> = ({ invoiceId, setInvoiceForModal }) => (
  <ModalWrapper modalId={INVOICE_MODAL_ID}>
    <InvoiceModal
      invoiceId={invoiceId}
      setInvoiceForModal={setInvoiceForModal}
    />
  </ModalWrapper>
)

export default WrappedInvoiceModal

const Wrapper = styled.div`
  min-height: 400px;
`

const StyledPdfFileIcon = styled(PdfFileIcon)`
  margin-right: 4px;
  margin-left: -3px;
  width: 20px;
  height: 20px;
`
const StyledAnchorTag = styled.a`
  color: ${motifPalette['dark'].text0};
  &:hover {
    color: ${motifPalette['dark'].text1};
  }
`
