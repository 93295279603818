import moment, { Moment } from 'moment'
import { createSelector } from 'reselect'
import { last, sum, unionBy } from 'lodash-es'

import createDataModule, { defaultInitialState } from 'utils/moduleCreator'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { ModuleMeta } from 'types/types'

import { InvoiceData, Invoices, StripeInvoice } from './types'

const LIMIT_DEFAULTS = 50
const INVOICES_DEFAULTS = {
  data: [],
  meta: { has_more: false, next_starting_after: '' },
}

const {
  api: { billing },
} = __CONFIG__

const getUrl = (
  url: string,
  {
    starting_after,
    region,
    created_lte,
    created_gt,
    limit = LIMIT_DEFAULTS,
  }: {
    starting_after?: string
    region?: string
    created_lte?: number
    created_gt?: number
    limit?: number
  } = {}
) => {
  const qStartingAfter = starting_after
    ? `&starting_after=${starting_after}`
    : ''
  const qRegion = region ? `&region=${region}` : ''
  const qCreatedLte = created_lte
    ? `&created[lte]=${Math.floor(created_lte / 1000)}`
    : ''
  const qCreatedGt = created_gt
    ? `&created[gt]=${Math.floor(created_gt / 1000)}`
    : ''
  const qLimit = limit ? `&limit=${limit}` : ''
  const qs = `?expand[]=data.charge${qLimit}${qStartingAfter}${qRegion}${qCreatedLte}${qCreatedGt}`
  return `${url}${qs}`
}

const mergeFn = (data: InvoiceData, prevData: Invoices) => {
  const newData: Invoices = {
    us: INVOICES_DEFAULTS,
    ca: INVOICES_DEFAULTS,
    gb: INVOICES_DEFAULTS,
    eu: INVOICES_DEFAULTS,
    au: INVOICES_DEFAULTS,
    ae: INVOICES_DEFAULTS,
    sg: INVOICES_DEFAULTS,
    jp: INVOICES_DEFAULTS,
    row: INVOICES_DEFAULTS,
  }
  try {
    const regions = Object.keys(prevData) as StripeRegionCode[]
    regions.forEach((region) => {
      const regionalData = data[region as keyof InvoiceData]
      const regionalInvoicesArr = regionalData?.data ?? []
      const updatedData = unionBy(
        regionalInvoicesArr,
        prevData[region as keyof Invoices].data,
        'id'
      )
      const updatedMeta = {
        has_more: regionalData ? regionalData.has_more : false,
        next_starting_after:
          regionalInvoicesArr.length > 0
            ? regionalInvoicesArr[regionalInvoicesArr.length - 1].id
            : '',
      }
      newData[region] = {
        data: updatedData,
        meta: updatedMeta,
      }
    })
    return newData
  } catch (e) {
    console.error(e)
  }
}

export const dataKey = 'invoices'

export const dataModule = createDataModule<Invoices>(
  dataKey,
  billing.paths.invoices,
  billing.base,
  {
    ...defaultInitialState,
    data: {
      us: INVOICES_DEFAULTS,
      ca: INVOICES_DEFAULTS,
      gb: INVOICES_DEFAULTS,
      eu: INVOICES_DEFAULTS,
      au: INVOICES_DEFAULTS,
      ae: INVOICES_DEFAULTS,
      sg: INVOICES_DEFAULTS,
      jp: INVOICES_DEFAULTS,
      row: INVOICES_DEFAULTS,
    },
  },
  getUrl,
  mergeFn
)

export const {
  mergeFetch,
  selectIsLoading: selectIsInvoicesLoading,
  reducer: invoicesReducer,
  rootSaga: invoicesRootSaga,
} = dataModule

export const fetchInvoices = (payload?: any, meta?: ModuleMeta<any>) => {
  return mergeFetch(payload, meta)
}

export const selectInvoicesMeta = createSelector(
  dataModule.selectData,
  (data) =>
    Object.entries(data).map(([key, value]) => ({ region: key, ...value.meta }))
)

export const selectHasMore = createSelector(selectInvoicesMeta, (data) =>
  data.some((region) => region.has_more)
)

export const selectInvoices = createSelector(
  dataModule.selectData,
  (invoicesData): StripeInvoice[] => {
    const invoiceRegions = Object.keys(invoicesData) as StripeRegionCode[]
    if (!invoiceRegions.length) {
      return []
    }

    const userInvoices = invoiceRegions
      .map((region) => {
        return (
          invoicesData[region as keyof Invoices].data?.map((invoice) => {
            return { ...invoice, region }
          }) ?? []
        )
      })
      .flat()

    return userInvoices.sort((a, b) => {
      const billingDateA = a.status_transitions.finalized_at ?? 0
      const billingDateB = b.status_transitions.finalized_at ?? 0
      return billingDateB - billingDateA
    })
  }
)

export const selectInvoice = (invoiceId: string) =>
  createSelector(selectInvoices, (invoices) =>
    invoices.find(({ id }) => id === invoiceId)
  )

export interface InvoiceItem {
  monthLabel: string
  id: string
  month: string
  rawTime: Moment
  total: number
  children: StripeInvoice[]
  comperator?: number
}

export const selectInvoiceTableItems = createSelector(
  selectInvoices,
  (invoices) => {
    const filteredInvoices = invoices.filter(
      (invoice) => last(invoice.lines.data)?.plan
    )
    const itemsMap = filteredInvoices.reduce<{ [key: string]: InvoiceItem }>(
      (agg, invoice) => {
        const finalizedTs = invoice.status_transitions.finalized_at
          ? moment(invoice.status_transitions.finalized_at * 1000)
          : moment()
        const key = finalizedTs.format('M.Y')
        if (!agg[key]) {
          agg[key] = {
            id: key,
            monthLabel: `${finalizedTs.format('MMMM')} ${finalizedTs.format(
              'YYYY'
            )}`,
            month: finalizedTs.format('MMMM'),
            rawTime: finalizedTs,
            total: invoice.status === 'void' ? 0 : invoice.total,
            children: [invoice],
          }
          return agg
        }

        const item = agg[key]
        if (invoice.status !== 'void') item.total += invoice.total
        item.children.push(invoice)

        return agg
      },
      {}
    )

    return Object.keys(itemsMap).map((key) => {
      itemsMap[key].comperator = sum(key.split('.').map((n) => parseInt(n, 10)))
      return itemsMap[key]
    })
  }
)
