import type Stripe from 'stripe'
import { createSelector } from 'reselect'

import { StripeRegionCode } from 'features/stripe/ui/types'
import createDataModule from 'utils/moduleCreator'

const {
  api: { billing },
} = __CONFIG__

export type BusinessProfileWithRegion = Record<
  StripeRegionCode,
  Stripe.Account.BusinessProfile
>

export const businessProfiledDataKey = 'businessProfiledDataKey'

const getUrl = (url: string, region: string) => `${url}?region=${region}`

export const dataModule = createDataModule<BusinessProfileWithRegion>(
  businessProfiledDataKey,
  billing.paths.businessProfile,
  billing.base,
  undefined,
  getUrl
)

export const {
  fetch: fetchBusinessProfile,
  selectIsLoading: selectIsBusinessProfileLoading,
  reducer: BusinessProfileReducer,
  rootSaga: BusinessProfileRootSaga,
} = dataModule

export const selectBusinessProfile = createSelector(
  dataModule.selectData,
  (businessProfile) => {
    const region = Object.keys(businessProfile)[0] as StripeRegionCode

    return businessProfile[region]
  }
)
