import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { setVisibleModal } from 'modules/modals'
import { ADD_PAYMENT_METHOD_MODAL_ID } from 'features/payment-methods/ui/AddPaymentMethodModal'
import { payInvoice } from 'features/subscriptions/module/invoicesUpdate'
import { CHOOSE_PAYMENT_METHOD_MODAL_ID } from 'features/payment-methods/ui/ChoosePaymentMethodModal'
import type { StripeRegionCode } from 'features/stripe/ui/types'

import { updateSubscription } from '../../module/subscriptionsUpdate'
import { useToasts } from './useToasts'
import { selectInvoices } from 'features/subscriptions/module/invoices'

export const useSubscriptionPaymentMethods = () => {
  const dispatch = useDispatch()
  const {
    payInvoiceSuccessToast,
    payInvoiceErrorToast,
    updateSubscriptionErrorToast,
  } = useToasts()
  const invoices = useSelector(selectInvoices)

  const handleCardChange = useCallback(
    (value: string, fullSubscription: FullSubscription) => {
      const { id, region, default_payment_method } = fullSubscription

      const valuesToPick = default_payment_method
        ? 'default_payment_method'
        : 'default_source'

      const payload = default_payment_method
        ? { default_payment_method: value }
        : { default_source: value }

      dispatch(
        updateSubscription(
          {
            id,
            region,
            ...payload,
          },
          {
            pick: [valuesToPick],
            onError: () => updateSubscriptionErrorToast(),
          }
        )
      )
    },
    [dispatch, updateSubscriptionErrorToast]
  )

  const addPaymentMethod = useCallback(
    (region: StripeRegionCode) =>
      dispatch(
        setVisibleModal(ADD_PAYMENT_METHOD_MODAL_ID, {
          subscriptionRegion: region,
        })
      ),
    [dispatch]
  )

  const openChoosePaymentMethodModal = useCallback(
    (fullSubscription) =>
      dispatch(
        setVisibleModal(CHOOSE_PAYMENT_METHOD_MODAL_ID, {
          fullSubscription,
        })
      ),
    [dispatch]
  )

  const handlePayInvoice = useCallback(
    (fullSubscription: FullSubscription, cardId?: string) => {
      const {
        id,
        region,
        default_source,
        isSuspended,
        default_payment_method,
      } = fullSubscription

      const openSubscriptionInvoices = invoices.filter(
        (invoice) => invoice.subscription === id && invoice.status === 'open'
      )

      const valuesToPick = default_payment_method ? 'payment_method' : 'source'

      const payload = default_payment_method
        ? { payment_method: cardId || default_payment_method }
        : { source: cardId || default_source }

      openSubscriptionInvoices?.forEach((invoice) => {
        dispatch(
          payInvoice(
            {
              id: invoice?.id,
              region: region,
              ...payload,
            },
            {
              pick: isSuspended ? [valuesToPick] : [],
              onSuccess: () => payInvoiceSuccessToast(isSuspended),
              onError: (data = {}) => payInvoiceErrorToast(data),
            }
          )
        )
      })
    },
    [dispatch, invoices, payInvoiceErrorToast, payInvoiceSuccessToast]
  )

  return {
    handleCardChange,
    addPaymentMethod,
    handlePayInvoice,
    openChoosePaymentMethodModal,
  }
}
