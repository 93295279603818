import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import createDataModule from 'utils/moduleCreator'
import { StripeData } from 'types/types'
import { closeVisibleModal } from 'modules/modals'
import { CardType, UpdateCardData } from './types'
import { fetchPaymentMethods } from './fetchPaymentMethods'

const {
  api: { billing },
} = __CONFIG__

export const updateCardDataKey = 'updateCard'

const getUrl = (url: string, card: UpdateCardData) =>
  `${url}/${card.id}?region=${card.region}`

const dataModule = createDataModule<StripeData<CardType>>(
  updateCardDataKey,
  billing.paths.cards,
  billing.base,
  undefined,
  getUrl
)

export const {
  CREATE_DONE: UPDATE_CARD_DONE,
  selectIsLoading: selectIsUpdateCardLoading,
  resetErrors: resetUpdateCardErrors,
  reducer: updateCardReducer,
} = dataModule

export const selectCardsApiError = createSelector(
  dataModule.selectErrors,
  (errors) => errors.message ?? null
)

export const selectStripeCardError = createSelector(
  dataModule.selectErrors,
  (errors) => errors.metaData?.dependencyMessage ?? null
)

const UPDATE_CARD = `${updateCardDataKey}/UPDATE_CARD`
export const editCard = (card: UpdateCardData) => {
  return {
    type: UPDATE_CARD,
    payload: card,
  }
}

function* updateCardSaga(action: ReturnType<typeof editCard>) {
  yield put(dataModule.create(action.payload, { pick: ['owner', 'card'] }))
}

function* cardDoneSaga() {
  yield put(fetchPaymentMethods())
  yield put(closeVisibleModal())
}

function* subscribeToUpdateCardSaga() {
  yield takeEvery(UPDATE_CARD, updateCardSaga)
}

function* subscribeToUpdateCardDoneSaga() {
  yield takeEvery(UPDATE_CARD_DONE, cardDoneSaga)
}

export function* updateCardRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToUpdateCardDoneSaga),
    spawn(subscribeToUpdateCardSaga),
  ])
}
