import React, { useEffect, useState } from 'react'

import styled from 'theme/styled'
import ProtectedPage from 'pages/ProtectedPage'

import { RequestsContainer } from './RequestsContainer'
import { useFetchAll } from './useFetchAll'
import { isIpBlacklisted } from 'utils/redirectIfIpIsBlacklisted'
import { useDispatch } from 'react-redux'
import { setVisibleModal } from 'modules/modals'
import {
  BLACKLISTED_IP_INFO_MODAL,
  WrappedBlacklistedIpInfoModal,
} from 'components/BlacklistedIpInfoModal'

export const Requests: React.FC = () => {
  const { fetchAllTickets } = useFetchAll()
  const dispatch = useDispatch()

  const [isCountryBlacklisted, setIsCountryBlacklisted] = useState<
    boolean | null
  >(null)

  useEffect(() => {
    const redirectIfIpIsBlacklisted = async () => {
      const { isBlacklisted, country } = await isIpBlacklisted()
      if (isBlacklisted) {
        setIsCountryBlacklisted(true)
        dispatch(setVisibleModal(BLACKLISTED_IP_INFO_MODAL, { country }))
      }
    }
    redirectIfIpIsBlacklisted()
  }, [dispatch])

  useEffect(() => {
    fetchAllTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ProtectedPage>
      <Container>
        {isCountryBlacklisted ? null : <RequestsContainer />}
      </Container>
      <WrappedBlacklistedIpInfoModal />
    </ProtectedPage>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
`
