import { all, spawn } from 'redux-saga/effects'
import { uniq } from 'lodash-es'
import createDataModule from 'utils/moduleCreator'
import { createSelector } from 'reselect'
import { CustomerPaymentMethods } from './types'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { regionsWithMultipleCountries } from 'features/stripe/config'

const {
  api: { billing },
} = __CONFIG__

export const fetchPaymentMethodsDataKey = 'fetchPaymentMethods'

const getUrl = (url: string) => `${url}?type=card&limit=100`

const dataModule = createDataModule<CustomerPaymentMethods>(
  fetchPaymentMethodsDataKey,
  billing.paths.paymentMethods,
  billing.base,
  undefined,
  getUrl
)

export const {
  fetch: fetchPaymentMethods,
  selectIsLoading: selectIsFetchPaymentMethodsLoading,
  reducer: fetchPaymentMethodsReducer,
  selectData: selectAllPaymentMethods,
} = dataModule

export const selectPaymentMethods = createSelector(
  dataModule.selectData,
  (data) => {
    const cardRegions = Object.keys(data) as StripeRegionCode[]

    if (!cardRegions.length) {
      return []
    }

    const userCards = cardRegions.flatMap((region) => {
      return (
        data[region]?.data.map((card) => {
          return { ...card, region }
        }) || []
      )
    })

    return userCards
  }
)

export const selectPaymentMethodCountries = createSelector(
  dataModule.selectData,
  (data) => {
    const userCountries: string[] = []
    const cardRegions = Object.keys(data) as StripeRegionCode[]

    cardRegions.map((region) => {
      const cards = data[region]?.data

      if (cards?.length && regionsWithMultipleCountries.includes(region)) {
        cards.forEach((card) => {
          if (card.card?.country) {
            const { country } = card.card
            userCountries.push(country.toLowerCase())
          }
        })
      }

      if (cards?.length && !regionsWithMultipleCountries.includes(region)) {
        userCountries.push(region)
      }
    })

    return uniq(userCountries)
  }
)

export function* fetchPaymentMethodsRootSaga() {
  yield all([spawn(dataModule.rootSaga)])
}
