import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import createDataModule from 'utils/moduleCreator'
import { ReduxAction } from 'types/types'
import { CHECK_AUTH } from 'features/auth/modules/auth'
import type { StripeRegionCode } from '../ui/types'
import { GlobalCustomerData, fetchCustomer } from './stripeCustomer'
import { fetchPaymentMethods } from 'features/payment-methods/modules/fetchPaymentMethods'

const {
  api: { billing },
} = __CONFIG__

export const createCustomerDataKey = 'createStripeCustomer'

const dataModule = createDataModule<GlobalCustomerData | undefined>(
  createCustomerDataKey,
  billing.paths.customer,
  billing.base
)

export const {
  CREATE_DONE: CUSTOMER_CREATE_DONE,
  CREATE_FAILED: CUSTOMER_CREATE_FAILED,
  reducer: createStripeCustomerReducer,
} = dataModule

export const createCustomer = (region: StripeRegionCode) => {
  return {
    type: `${createCustomerDataKey}/CREATE`,
    payload: {},
    urlPath: `?region=${region}`,
  }
}

function* customerCreatedSaga() {
  yield put(fetchCustomer())
}

function* fetchStripeDataSaga({ meta = {} }: ReduxAction) {
  if (meta && meta['redux-pack/LIFECYCLE'] !== 'success') {
    return null
  }

  yield put(fetchCustomer())
  yield put(fetchPaymentMethods())
}

function* subscribeToStripeCustomerSagas() {
  yield takeEvery(CUSTOMER_CREATE_DONE, customerCreatedSaga)
  yield takeEvery(CHECK_AUTH, fetchStripeDataSaga)
}

export function* createStripeCustomerRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToStripeCustomerSagas)])
}
