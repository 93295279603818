import React, { ReactElement, useCallback, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import EntityToast from '@ubnt/ui-components/Toast/EntityToast'
import { ToastContext } from '@ubnt/ui-components/Toast/ToastContext'
import { QuoteIcon } from '@ubnt/icons'

import { isString } from 'utils/typeGuards'

export const useToasts = () => {
  const toast = useContext(ToastContext)
  const intl = useIntl()

  const payInvoiceSuccessToast = useCallback(
    (isSuspended) => {
      toast.createNotification(
        <EntityToast
          icon={<QuoteIcon />}
          stateIndicator="success"
          title={intl.formatMessage({
            id: 'SETTINGS_SUBSCRIPTIONS_INVOICE_PAID',
          })}
          duration={5000}
          details={intl.formatMessage({
            id: isSuspended
              ? 'SETTINGS_SUBSCRIPTIONS_SUSPENDED_INVOICE_PAID'
              : 'SETTINGS_SUBSCRIPTIONS_OVERDUE_INVOICE_PAID',
          })}
          onClose={(_e, id: string | undefined) => {
            if (isString(id)) {
              toast.removeNotification(id)
            }
          }}
        />
      )
    },
    [intl, toast]
  )

  const payInvoiceErrorToast = useCallback(
    (data = {}) => {
      let errorMessage: ReactElement | string
      let detailsMessage: ReactElement | string = ''
      if (data.message === 'Something happened on the external API.') {
        errorMessage = data.metaData.dependencyMessage
      } else {
        errorMessage = (
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COULD_NOT_PAY_INVOICE" />
        )
        detailsMessage = data?.message ?? ''
      }
      toast.createNotification(
        <EntityToast
          icon={<QuoteIcon />}
          stateIndicator="danger"
          title={errorMessage}
          duration={5000}
          details={detailsMessage}
          onClose={(_e, id: string | undefined) => {
            if (isString(id)) {
              toast.removeNotification(id)
            }
          }}
        />
      )
    },
    [toast]
  )

  const updateSubscriptionErrorToast = useCallback(() => {
    toast.createNotification(
      <EntityToast
        icon={<QuoteIcon />}
        stateIndicator="danger"
        title={<FormattedMessage id="GENERIC_ERROR_MESSAGE" />}
        details={
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COULD_NOT_UPDATE_CARD" />
        }
        duration={5000}
        onClose={(_e, id: string | undefined) => {
          if (isString(id)) {
            toast.removeNotification(id)
          }
        }}
      />
    )
  }, [toast])

  return {
    payInvoiceSuccessToast,
    payInvoiceErrorToast,
    updateSubscriptionErrorToast,
  }
}
