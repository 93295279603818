import { createSelector } from 'reselect'
import createDataModule from 'utils/moduleCreator'

const {
  api: { accountBE },
} = __CONFIG__

export const loginIssueFeedbackDataKey = 'loginIssueFeedback'

const getUrl = (url: string, mfaId: string) => `${url}/${mfaId}`

const dataModule = createDataModule<{ isAllowed: boolean }>(
  loginIssueFeedbackDataKey,
  accountBE.paths.loginIssueFeedback,
  accountBE.base,
  undefined,
  getUrl
)

export const {
  fetch: fetchCanSubmitFeedback,
  selectData,
  rootSaga: loginIssueFeedbackRootSaga,
  reducer: loginIssueFeedbackReducer,
} = dataModule

export const selectHasSubmittedMfaFeedbackRecently = createSelector(
  selectData,
  ({ isAllowed }) => {
    return !isAllowed
  }
)
