import { createSelector } from 'reselect'
import type Stripe from 'stripe'

import { StripeRegionCode } from 'features/stripe/ui/types'
import { ProductName } from 'types/enums'

import { selectProducts } from '../module/products'
import { selectSubscriptions } from '../module/subscriptions'
import { selectInvoices } from '../module/invoices'
import { selectUpcomingInvoices } from '../module/invoicesUpcoming'
import { StripeInvoice } from '../module/types'
import { DeviceUmrData, selectDeviceUmrInfo } from '../module/deviceUmr'
import { getSubscriptionProductLine } from './utils'
import { getAmount } from 'features/invoices/ui/utils'

export interface FullSubscription {
  id: string
  name: ProductName
  productLine: ProductName
  amount: number
  metadata: Stripe.Metadata
  status: Stripe.Subscription.Status
  plan: Stripe.Plan | null | undefined
  region: StripeRegionCode
  product: Stripe.Product | undefined
  current_period_end: number
  quantity: number | undefined
  invoice: StripeInvoice | undefined
  items: Stripe.ApiList<Stripe.SubscriptionItem>
  upcomingInvoice: StripeInvoice | undefined
  deviceUmrInfo: DeviceUmrData[]
  isSuspended: boolean
  isFailed: boolean
  default_source: string | Stripe.CustomerSource | null
  default_payment_method: string | Stripe.PaymentMethod | null
  display: boolean
  isInProgress: boolean
  isActive: boolean
  cardNotFound: boolean
  cancelAtPeriodEnd?: boolean
}

const SUBSCRIPTION_STATUSES_TO_SHOW = [
  'active',
  'past_due',
  'canceled',
  'trialing',
]

export enum SubscriptionStatus {
  ACTIVE = 'active',
  TRIALING = 'trialing',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
}

export const selectFullSubscriptions = createSelector(
  selectSubscriptions,
  selectProducts,
  selectInvoices,
  selectUpcomingInvoices,
  selectDeviceUmrInfo,
  (subscriptions, products, invoices, upcomingInvoices, deviceUmrInfo) => {
    return subscriptions
      .filter(({ status }) => SUBSCRIPTION_STATUSES_TO_SHOW.includes(status))
      .flatMap((subscription) => {
        const {
          region,
          status,
          current_period_end,
          plan,
          metadata,
          quantity,
          id,
          items,
          default_source,
          latest_invoice,
          default_payment_method,
        } = subscription

        const findPossibleProducts = (
          possibleProduct:
            | string
            | Stripe.Product
            | Stripe.DeletedProduct
            | null
        ) =>
          products.find(
            ({ id }) =>
              id ===
              (typeof possibleProduct === 'string'
                ? possibleProduct
                : possibleProduct?.id)
          )

        const possibleProduct = plan?.product
        const product: Stripe.Product | undefined =
          findPossibleProducts(possibleProduct)

        const invoice =
          typeof latest_invoice === 'string'
            ? invoices.find(({ id }) => latest_invoice === id)
            : (latest_invoice as StripeInvoice)

        const upcomingInvoice: StripeInvoice | undefined =
          upcomingInvoices?.find(
            (upcomingInvoice) => id === upcomingInvoice.subscription
          )

        const isActiveInvoice = !!invoice && invoice.status !== 'void'
        const isInvoicePaid = !!invoice?.paid || invoice?.amount_remaining === 0
        const isSuspended =
          status === 'canceled' && isActiveInvoice && !isInvoicePaid
        const isFailed = status === 'past_due' || isSuspended
        const cancelAtPeriodEnd = subscription.cancel_at_period_end

        let amount = upcomingInvoice
          ? getAmount(upcomingInvoice?.amount_due, invoice?.currency)
          : 0

        if (isActiveInvoice && !isInvoicePaid) {
          const amountDivided = getAmount(
            invoice?.amount_remaining,
            invoice?.currency
          )
          amount = amountDivided ?? 0
        }

        const isInProgress = isSuspended && invoice?.status === 'draft'

        const productLine = getSubscriptionProductLine(
          product?.name ?? '',
          metadata?.ui_product_line
        )

        const name = productLine

        const cardNotFound =
          productLine !== ProductName.UNIFI_TALK &&
          !isFailed &&
          !default_source &&
          !default_payment_method

        const isActive =
          status === 'active' &&
          (productLine !== ProductName.UNIFI_TALK || !!quantity)

        let display = true

        const noInvoicePayment = !invoice || invoice.status === 'void'

        if (status === 'canceled' && (isInvoicePaid || noInvoicePayment))
          display = false

        return [
          {
            id,
            name,
            productLine,
            amount,
            metadata,
            status,
            plan,
            region,
            cardNotFound,
            product,
            quantity,
            current_period_end,
            isInProgress,
            invoice,
            items,
            upcomingInvoice,
            deviceUmrInfo,
            isSuspended,
            isFailed,
            default_source,
            default_payment_method,
            display,
            isActive,
            cancelAtPeriodEnd,
          },
        ]
      })
  }
)

export const selectFullSubscriptionsToDisplay = createSelector(
  selectFullSubscriptions,
  (subscriptions) =>
    subscriptions.filter((subscription) => subscription.display)
)

export const selectOngoingTalkRegions = createSelector(
  selectFullSubscriptionsToDisplay,
  (subscriptions) => {
    const talkRegions: Partial<Record<StripeRegionCode, boolean>> = {}
    for (const subscription of subscriptions) {
      if (subscription.productLine === ProductName.UNIFI_TALK) {
        talkRegions[subscription.region] = true
      }
    }
    return talkRegions
  }
)
