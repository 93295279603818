import { CustomerPaymentMethods } from 'features/payment-methods/modules/types'
import { regionsWithTaxIdSupport } from 'features/stripe/config'
import { validStripeCountryMap } from 'features/stripe/ui/regions'
import { StripeCountryCode, StripeRegionCode } from 'features/stripe/ui/types'

export const customerHasTaxIdAcceptedCountries = (
  fullCardData: CustomerPaymentMethods
): boolean => {
  const allCardRegions = Object.keys(fullCardData) as StripeRegionCode[]

  return allCardRegions.some((region) => {
    if (!regionsWithTaxIdSupport.includes(region)) {
      return false
    }

    return fullCardData[region]?.data.some((card) => {
      const countryData = validStripeCountryMap.get(
        card.billing_details.address?.country?.toLowerCase() as StripeCountryCode
      )
      return !!countryData?.taxId?.length
    })
  })
}
