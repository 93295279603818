import { IntlShape } from 'react-intl'
import { omit, reduce } from 'lodash-es'

import { ChatDepartment, SupportFormState } from 'features/support/unifi/types'

import { zdFieldValues } from '../config/allZdValues'
import {
  assistance,
  device,
  model,
  tech,
  trouble,
} from '../config/ConfigOptions'
import {
  App,
  Device,
  FormConfig,
  Model,
  Tech,
  Trouble,
  UniFiStateValues,
} from '../config/types'

const {
  chat: {
    wirelessDepartmentId,
    routingDepartmentId,
    securityDepartmentId,
    protectDepartmentId,
    talkDepartmentId,
    accessDepartmentId,
    connectDepartmentId,
    identityDepartmentId,
    osDepartmentId,
  },
} = __CONFIG__

export const getTagsToRemove = () => {
  const allAssistanceTags = assistance.map((assist) => assist.id)
  const allDeviceTags = device.map((dev) => dev.id)
  const allModelTags = model.map((mod) => mod.id)
  const allTechTags = tech.map((tec) => tec.id)
  const allTroubleTags = trouble.map((troub) => troub.id)

  return [
    ...allAssistanceTags,
    ...allDeviceTags,
    ...allModelTags,
    ...allTechTags,
    ...allTroubleTags,
  ]
}

export const getTagsToAdd = (values: SupportFormState) => {
  const tagsToAdd = ['origin_chat_account']
  const formValuesWithoutSomeValues: Record<string, string | undefined> = {
    ...values,
    console: values.console?.id,
    talkVip: '',
  }
  delete formValuesWithoutSomeValues['description']
  delete formValuesWithoutSomeValues['attachments']
  delete formValuesWithoutSomeValues['supportFile']
  delete formValuesWithoutSomeValues['totalConsoles']
  delete formValuesWithoutSomeValues['talk']
  const selectedTags = Object.values(formValuesWithoutSomeValues)
  selectedTags.forEach((tag) => !!tag && tagsToAdd.push(tag))
  return tagsToAdd
}

const osDepartment = {
  id: osDepartmentId,
  name: 'UniFi OS',
  status: 'online',
}

const protectDepartment = {
  id: protectDepartmentId,
  name: 'UniFi Protect',
  status: 'online',
}

const wirelessDepartment = {
  id: wirelessDepartmentId,
  name: 'UniFi Wireless',
  status: 'online',
}

const routingDepartment = {
  id: routingDepartmentId,
  name: 'UniFi Routing & Switching',
  status: 'online',
}

const talkDepartment = {
  id: talkDepartmentId,
  name: 'UniFi Talk',
  status: 'online',
}

const accessDepartment = {
  id: accessDepartmentId,
  name: 'UniFi Access',
  status: 'online',
}

const connectDepartment = {
  id: connectDepartmentId,
  name: 'UniFi Connect',
  status: 'online',
}

const identityDepartment = {
  id: identityDepartmentId,
  name: 'UniFi Identity',
  status: 'online',
}

const securityDepartment = {
  id: securityDepartmentId,
  name: 'Security & VPN',
  status: 'online',
}

// TODO: Clean up this function
export const getChatDepartment = (
  tech?: Tech,
  app?: App,
  model?: Model,
  trouble?: Trouble,
  device?: Device
): ChatDepartment => {
  if (
    trouble &&
    [
      Trouble.appNoStart,
      Trouble.updates,
      Trouble.noSignIn,
      Trouble.adminUsersPermissions,
    ].includes(trouble)
  ) {
    return osDepartment
  }

  if (
    model &&
    [
      Model.cloudGateway,
      Model.cloudKey,
      Model.officialCloudHosting,
      Model.selfHostedNetServer,
    ].includes(model)
  ) {
    return osDepartment
  }

  if (model && model === Model.networkVideoRecorder) {
    return protectDepartment
  }

  if (model && model === Model.standaloneAp) {
    return wirelessDepartment
  }

  if (device && device === Device.gatewayConsole) {
    return osDepartment
  }

  if (device && device === Device.ap) {
    return wirelessDepartment
  }

  if (device && device === Device.switch) {
    return routingDepartment
  }

  if (device && device === Device.camera) {
    return protectDepartment
  }

  if (device && device === Device.talkDevice) {
    return talkDepartment
  }

  if (device && device === Device.accessDevice) {
    return accessDepartment
  }

  if (device && device === Device.connectDevice) {
    return connectDepartment
  }

  if (app && app === App.network) {
    return routingDepartment
  }

  if (app && app === App.protect) {
    return protectDepartment
  }

  if (app && app === App.talk) {
    return talkDepartment
  }

  if (app && app === App.access) {
    return accessDepartment
  }

  if (app && app === App.identity) {
    return identityDepartment
  }

  if (app && app === App.connect) {
    return connectDepartment
  }

  switch (tech) {
    case Tech.wifi:
      return wirelessDepartment
    case Tech.routingAndSwitching:
      return routingDepartment
    case Tech.securityAndVPN:
      return securityDepartment
    case Tech.protect:
      return protectDepartment
    case Tech.talk:
      return talkDepartment
    case Tech.access:
      return accessDepartment
    case Tech.connect:
      return connectDepartment
    default:
      return identityDepartment
  }
}

const cleanVersionNumber = (version?: string) => {
  if (!version) return ''
  /* For some reason Zendesk chat assumes that all . are related to links, therefore
    we must replace them with U+2024 - One Dot Leader - however we cannot use the unicode number or
    html-code to do the replacement as the widget will not translate them */
  return `- v${version.replaceAll('.', '․')}`
}

export const getFormattedMessage = (
  config: FormConfig,
  values: SupportFormState,
  formSections: string[],
  intl: IntlShape,
  isSupportFileAllowed: boolean,
  consoleName?: string,
  consoleV?: string,
  sendsafelyAttachmentUrl?: string
) => {
  let text = ''
  const consoleVersion = cleanVersionNumber(consoleV)

  const stateFields = omit(
    config,
    'subject',
    'totalDevices',
    'mac',
    'version',
    'releaseChannel',
    'appVersion',
    'appVersions',
    'sentryUserId',
    'sentryEventId',
    'totalConsoles',
    'rawData',
    'supportFile',
    'talk'
  )

  const custom_fields = reduce(
    stateFields,
    (result, field, key) => {
      const question = field.fieldTitle
      let value = values[key as keyof SupportFormState]
      if (value === undefined || !question) return result
      if (typeof value !== 'string' && typeof value !== 'boolean') {
        value = value.id
      }
      if (typeof value === 'boolean') {
        value = 'talk_regular'
      }
      return [
        ...result,
        {
          question,
          value,
          key,
        },
      ]
    },
    [] as { question: string; value: string; key: string }[]
  )

  formSections.forEach((section) => {
    const fieldValue = custom_fields.find((field) => field.key === section)
    if (!fieldValue) return

    const label = zdFieldValues[fieldValue.value]

    text += `- ${intl.formatMessage({ id: fieldValue.question })} \n`

    if (
      fieldValue.key !== UniFiStateValues.DESCRIPTION &&
      fieldValue.key !== UniFiStateValues.CONSOLE &&
      label
    ) {
      text += `${label} \n \n`
    }

    if (fieldValue.key === UniFiStateValues.CONSOLE) {
      text += `${consoleName || label} ${consoleVersion} \n \n`
    }

    if (fieldValue.key === UniFiStateValues.DESCRIPTION) {
      text += `${fieldValue.value} \n \n`
    }
  })

  if (sendsafelyAttachmentUrl) {
    text += '- Secure attachments: \n'
    text += `${sendsafelyAttachmentUrl} \n \n`
  }

  if (values.supportFile && isSupportFileAllowed) {
    text += '- Support file: \n'
    text += `${values.supportFile} \n \n`
  }

  return text
}
