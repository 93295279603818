import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'

import {
  CellColumnLabel,
  ChargeLabelCell,
  Container,
  LabelCell,
  Row,
} from '../Styles'
import { SubscriptionRow } from './SubscriptionRow'

interface Props {
  fullSubscriptions: FullSubscription[]
  mixedRegions: boolean
}

export const EnhancedThreatUpdatesList: React.FC<Readonly<Props>> = ({
  fullSubscriptions,
  mixedRegions,
}) => {
  const { isMaxXSmall } = useContext(MediaSizingContext)

  const [cardPopoverOpen, setCardPopoverOpen] = useState<number | null>(null)
  const [morePopoverOpen, setMorePopoverOpen] = useState<number | null>(null)

  if (!fullSubscriptions.length) return null

  return (
    <Container>
      <Row>
        <CellColumnLabel $mixedRegions={mixedRegions} $skipFirstColumn>
          {mixedRegions && <LabelCell />}
          <LabelCell>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_DEVICE_NAME" />
          </LabelCell>
          {!isMaxXSmall && (
            <>
              <LabelCell>
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_BILLING_DATE" />
              </LabelCell>
              <LabelCell>
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_PAYMENT_METHOD" />
              </LabelCell>
            </>
          )}
          <ChargeLabelCell>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_CHARGE" />
          </ChargeLabelCell>
        </CellColumnLabel>
      </Row>
      {fullSubscriptions.map(
        (fullSubscription: FullSubscription, i: number) => (
          <SubscriptionRow
            fullSubscription={fullSubscription}
            mixedRegions={mixedRegions}
            id={i}
            cardPopoverOpen={cardPopoverOpen}
            setCardPopoverOpen={setCardPopoverOpen}
            morePopoverOpen={morePopoverOpen}
            setMorePopoverOpen={setMorePopoverOpen}
          />
        )
      )}
    </Container>
  )
}
