import React from 'react'
import { FormattedMessage } from 'react-intl'
import { NumericFormat } from 'react-number-format'
import type Stripe from 'stripe'
import { Formatter } from '@ubnt/ui-components'

import { formatUTCtoHumanReadable, formatUTCtoMonthDay } from 'utils/dateTime'
import { media, space, typography } from 'theme'
import styled from 'theme/styled'

import { ProductName } from 'types/enums'
import { InvoiceItemsTableHeader } from './InvoiceItemsTableHeader'
import { getAmount, getCurrencySymbol, removeQuantityPrefix } from './utils'
import { InvoiceTaxBreakdown } from './InvoiceTaxBreakdown'

const METERED_KEY = 'metered'

const {
  featureFlags: { isTaxBreakdownEnabled },
} = __CONFIG__

interface Props {
  items: Stripe.InvoiceLineItem[]
  discount: Stripe.Discount | null
  discountAmount: number | undefined
  total: number
  currency: string
  metadata: Stripe.Metadata | null
  lines: Stripe.Invoice['lines']['data']
}

const InvoiceItemsTable: React.FC<Props> = ({
  items = [],
  discount,
  discountAmount,
  total,
  currency,
  metadata,
  lines,
}) => {
  const hasDiscount = discount?.coupon && !!discountAmount
  const currencySymbol = getCurrencySymbol(currency)

  return (
    <Wrapper>
      <>
        <DesktopHeadingsWrap>
          <InvoiceItemsTableHeader />
        </DesktopHeadingsWrap>

        <TableWrap>
          {items.map((item, index) => {
            let invoiceItemDescription = removeQuantityPrefix(
              item.description ?? ''
            )

            if (invoiceItemDescription.trim() === 'Intrusion Prevention') {
              invoiceItemDescription = ProductName.ENHANCED_TREAT_UPDATES
            }

            return (
              <RowWrapper key={`${item?.id}-${index}`}>
                <MobileRow>
                  <ItemInfoTitle>
                    <FormattedMessage id="COMMON_LABEL_DESCRIPTION" />
                  </ItemInfoTitle>
                  <ItemInfo>
                    {invoiceItemDescription} <br />
                    {`${formatUTCtoMonthDay(item.period.start)}${
                      item.plan
                        ? ` - ${formatUTCtoHumanReadable(item.period.end)}`
                        : ''
                    }`}
                  </ItemInfo>
                  <InvoiceItemsTableHeader />
                </MobileRow>

                <TableRow>
                  <TableCell className="name">
                    {invoiceItemDescription}
                    <br />
                    {`${formatUTCtoMonthDay(item.period.start)}${
                      item.plan
                        ? ` - ${formatUTCtoHumanReadable(item.period.end)}`
                        : ''
                    }`}
                  </TableCell>

                  <TableCell>
                    {item.plan?.usage_type === METERED_KEY ? (
                      <StyledFormatter
                        type="bytes"
                        input={item.quantity ?? 0}
                      />
                    ) : (
                      item.quantity
                    )}
                  </TableCell>

                  <TableCell className="price">
                    <NumericFormat
                      value={getAmount(
                        item.plan?.amount ?? item.amount,
                        item.currency
                      )}
                      displayType="text"
                      thousandSeparator
                      prefix={currencySymbol}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </TableCell>

                  <LastCell>
                    <NumericFormat
                      value={getAmount(item.amount, item.currency)}
                      displayType="text"
                      thousandSeparator
                      prefix={currencySymbol}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </LastCell>
                </TableRow>
              </RowWrapper>
            )
          })}

          {hasDiscount && (
            <DiscountRow>
              <TableCell>
                {discount?.coupon?.name ?? 'Test coupon name'}
              </TableCell>

              <DesktopCell></DesktopCell>
              <DesktopCell></DesktopCell>
              <DesktopCell></DesktopCell>

              <LastCell>
                -
                <NumericFormat
                  value={getAmount(discountAmount, currency)}
                  displayType="text"
                  thousandSeparator
                  prefix={currencySymbol}
                  decimalScale={2}
                  fixedDecimalScale
                />
              </LastCell>
            </DiscountRow>
          )}
        </TableWrap>

        {metadata && isTaxBreakdownEnabled && (
          <TableCell>
            <InvoiceTaxBreakdown
              metadata={metadata}
              lines={lines}
              currencySymbol={currencySymbol}
            />
          </TableCell>
        )}

        <TableFooter>
          <TotalPriceText>Total</TotalPriceText>
          <TotalCell>
            <NumericFormat
              value={getAmount(total, currency)}
              displayType="text"
              thousandSeparator
              prefix={currencySymbol}
              decimalScale={2}
              fixedDecimalScale
            />
          </TotalCell>
        </TableFooter>
      </>
    </Wrapper>
  )
}

export default InvoiceItemsTable

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: ${space['desktop-spacing-base-08']};
  padding: 0;

  @media (max-width: ${media.tablet}) {
    margin-top: ${space['desktop-spacing-base-04']};
  }
`

const TableCell = styled.div`
  padding: ${space['desktop-spacing-base-01']};
  text-align: left;

  @media (max-width: ${media.tablet}) {
    &.name,
    &.price {
      display: none;
    }
  }
`

const LastCell = styled.div`
  padding: ${space['desktop-spacing-base-01']};

  text-align: right;
`

const TableWrap = styled.div``

const DesktopCell = styled.div`
  @media (max-width: ${media.tablet}) {
    display: none;
  }
`

const TotalPriceText = styled.p`
  color: ${({ theme }) => theme.text2};
  font: ${typography['desktop-body']};
  text-align: right;

  @media (max-width: ${media.tablet}) {
    margin-bottom: 0;
  }
`

const TableRow = styled.div`
  align-items: center;
  display: grid;
  font: ${typography['desktop-body']};
  grid-column-gap: ${space['desktop-spacing-base-06']};
  grid-template-columns: 1fr 168px 87px 100px;
  @media (max-width: ${media.tablet}) {
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }
`

const DiscountRow = styled.div`
  align-items: center;
  display: flex;
  font: ${typography['desktop-body']};
  justify-content: space-between;
  padding: 8px 0;

  @media (max-width: ${media.tablet}) {
    display: flex;
    gap: 8px;
    grid-column-gap: ${space['desktop-spacing-base-06']};
    justify-content: space-between;
    border-top: ${({ theme }) => `1px solid ${theme.neutral03}`};
  }
`

const TableFooter = styled.div`
  display: grid;
  grid-template-columns: ${space['desktop-spacing-base-24']};
  justify-content: end;
  padding-top: ${space['desktop-spacing-base-02']};

  @media (max-width: ${media.tablet}) {
    padding-top: 0;
  }
`

const TotalCell = styled(LastCell)`
  font: ${typography['desktop-typography-heading-large']};
  margin-left: -${space['desktop-spacing-base-01']};
  margin-top: -14px;

  @media (max-width: ${media.tablet}) {
    margin: 0;
    text-align: right;
  }
`

const StyledFormatter = styled(Formatter)`
  font: ${typography['desktop-body']};
`

const ItemInfo = styled.div`
  align-items: flex-end;
  display: flex;
  font: ${typography['desktop-body']};
  justify-content: flex-start;
  padding: 0 ${space['desktop-spacing-base-01']}
    ${space['desktop-spacing-base-01']};
  text-align: left;
`

const ItemInfoTitle = styled(ItemInfo)`
  font: ${typography['desktop-typography-heading-large']};
  padding: 0 ${space['desktop-spacing-base-01']} 0;
  margin-bottom: 4px;
`

const DesktopHeadingsWrap = styled.div`
  @media (max-width: ${media.tablet}) {
    display: none;
  }
`

const MobileRow = styled.div`
  @media (min-width: ${media.tablet}) {
    display: none;
  }
`

const RowWrapper = styled.div`
  padding: 8px 0;
  @media (max-width: ${media.tablet}) {
    padding: ${space['desktop-spacing-base-02']} 0;
  }
`
