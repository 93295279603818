import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type Stripe from 'stripe'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { NumericFormat } from 'react-number-format'
import { Button } from '@ubnt/ui-components'
import { find } from 'lodash-es'

import { Section, SectionHeader } from 'components/Section.styles'
import { STRIPE_CHARGE_AUTOMATICALLY_KEY, StripeStatus } from 'sharedConstants'
import { RootState } from 'types/types'
import { setVisibleModal } from 'modules/modals'
import { isString } from 'utils/typeGuards'
import { getAmount, getCurrencySymbol } from 'features/invoices/ui/utils'
import { StyledDropdownArrow } from 'features/shared/styles'

import {
  fetchInvoices,
  selectHasMore,
  selectInvoiceTableItems,
  selectInvoicesMeta,
  selectIsInvoicesLoading,
} from '../module/invoices'
import {
  fetchInvoiceLines,
  selectIsInvoiceLinesLoading,
} from '../module/invoiceLines'
import { StripeInvoice } from '../module/types'
import { InvoiceTableHeader } from './InvoiceTableHeader'
import { DateCell } from './cells/DateCell'
import WrappedInvoiceModal, { INVOICE_MODAL_ID } from './InvoiceModal'
import {
  CellWrapper,
  Content,
  GreenStatusPill,
  InvoicesTableGrid,
  Label,
  LabelRow,
  OuterContentWrap,
  RedStatusPill,
  StatusPill,
  StyledAccordion,
  TableWrapper,
  ValueContainer,
  ViewButton,
  Wrapper,
} from './InvoiceTable.styles'
import { DeviceNameCell } from './cells/DeviceNameCell'
import { SubscriptionNameCell } from './cells/SubscriptionNameCell'
import { getProductIdFromInvoice } from '../utils'

const CARD_KEY = 'card'
const isCard = (type: any): type is Stripe.Charge.PaymentMethodDetails =>
  (type as Stripe.Charge.PaymentMethodDetails)?.type === CARD_KEY

interface StateProps {
  items: ReturnType<typeof selectInvoiceTableItems>
  itemsMeta: ReturnType<typeof selectInvoicesMeta>
  hasMore: ReturnType<typeof selectHasMore>
  invoiceId?: string | null
}

const InvoiceTable = ({ items, itemsMeta, hasMore, invoiceId }: StateProps) => {
  const [invoiceForModal, setInvoiceForModal] = useState<string | undefined>()
  const isFetchingMore = useSelector(selectIsInvoiceLinesLoading)
  const dispatch = useDispatch()
  const isInvoicesLoading = useSelector(selectIsInvoicesLoading)

  const foundListWithParamInvoice = useMemo(() => {
    return find(items, { children: [{ id: invoiceId }] })
  }, [items, invoiceId])

  const STATUS_CONTENT = {
    [StripeStatus.PAID]: (
      <GreenStatusPill>
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_PAID" />
      </GreenStatusPill>
    ),
    [StripeStatus.OPEN]: (
      <RedStatusPill>
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_AWAITING_PAYMENT" />
      </RedStatusPill>
    ),
    [StripeStatus.VOID]: (
      <StatusPill>
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_VOIDED" />
      </StatusPill>
    ),
    [StripeStatus.DRAFT]: (
      <StatusPill>
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_VOIDED" />
      </StatusPill>
    ),
  }

  const foundParamInvoice = useMemo(() => {
    return foundListWithParamInvoice?.children?.find(
      (invoice) => invoice.id === invoiceId
    )
  }, [invoiceId, foundListWithParamInvoice])

  const handleOpenInvoice = useCallback(
    (invoice: StripeInvoice) => {
      setInvoiceForModal(invoice.id)

      if (invoice.lines.has_more && !isFetchingMore) {
        dispatch(
          fetchInvoiceLines({ invoiceId: invoice.id, region: invoice.region })
        )
      }
      dispatch(setVisibleModal(INVOICE_MODAL_ID))
    },
    [dispatch, isFetchingMore]
  )

  useEffect(() => {
    if (!foundListWithParamInvoice || !foundParamInvoice) {
      return
    }

    handleOpenInvoice(foundParamInvoice)
  }, [foundListWithParamInvoice, foundParamInvoice, handleOpenInvoice])

  const loadMore = () => {
    itemsMeta.forEach((item) => {
      if (item.has_more && item.next_starting_after) {
        dispatch(
          fetchInvoices({
            region: item.region,
            starting_after: item.next_starting_after,
          })
        )
      }
    })
  }

  const sortedItems = useMemo(
    () =>
      items.sort((a, b) =>
        a.rawTime < b.rawTime ? 1 : b.rawTime < a.rawTime ? -1 : 0
      ),
    [items]
  )

  if (!items.length) return null

  const billedTo = (child: StripeInvoice) => {
    if (isString(child.charge)) return '—'

    if (isCard(child?.charge?.payment_method_details)) {
      const last4 = child?.charge?.payment_method_details?.card?.last4
      return last4 ? `····${last4}` : '—'
    }

    return '—'
  }

  return (
    <Section $omitMarginTop>
      <SectionHeader $marginBottom={16}>
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_HEADER_ARCHIVE" />
      </SectionHeader>

      <TableWrapper>
        <StyledAccordion
          variant="secondary"
          multiOpen
          contentContainerClassName="noMargin"
          items={sortedItems.map(({ id, monthLabel, children }) => ({
            id: id,
            openByDefault: foundListWithParamInvoice?.id === id,
            renderLabel: (
              itemExpanded: boolean,
              togglePanel: (id: string) => void
            ) => (
              <LabelRow onClick={() => togglePanel(id)}>
                <Label>{monthLabel}</Label>
                <StyledDropdownArrow
                  $isDropdownOpen={itemExpanded}
                  size="original"
                  variant="fill"
                />
              </LabelRow>
            ),
            renderContent: () => (
              <OuterContentWrap>
                <InvoiceTableHeader />

                {children.map((child: StripeInvoice) => {
                  const isVoided = child.status === StripeStatus.VOID

                  const COLUMNS = [
                    {
                      className: 'title-column',
                      content: (
                        <SubscriptionNameCell
                          productId={getProductIdFromInvoice(child)}
                          invoice={child}
                        />
                      ),
                    },
                    {
                      className: 'name-column',
                      content: (
                        <DeviceNameCell
                          productId={getProductIdFromInvoice(child)}
                          invoice={child}
                        />
                      ),
                    },
                    {
                      className: 'date-column',
                      content: isVoided
                        ? '—'
                        : child.status_transitions.finalized_at && (
                            <DateCell
                              dt={child.status_transitions.finalized_at}
                            />
                          ),
                    },
                    {
                      className: 'billed-column',
                      content: isVoided ? '—' : billedTo(child),
                    },
                    {
                      content:
                        child.status === StripeStatus.OPEN &&
                        child.collection_method ===
                          STRIPE_CHARGE_AUTOMATICALLY_KEY ? (
                          <GreenStatusPill>
                            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_AUTOPAID" />
                          </GreenStatusPill>
                        ) : (
                          child.status &&
                          child.status !== StripeStatus.UNCOLLECTIBLE &&
                          STATUS_CONTENT[child.status]
                        ),
                    },
                    {
                      content: (
                        <ValueContainer voided={isVoided}>
                          <NumericFormat
                            value={getAmount(child.total, child.currency)}
                            displayType="text"
                            thousandSeparator
                            prefix={getCurrencySymbol(child.currency)}
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </ValueContainer>
                      ),
                    },
                    {
                      content: (
                        <ViewButton onClick={() => handleOpenInvoice(child)}>
                          <FormattedMessage id="COMMON_ACTION_VIEW" />
                        </ViewButton>
                      ),
                    },
                  ]

                  return (
                    <Wrapper key={child.id} id={child.id}>
                      <InvoicesTableGrid>
                        {COLUMNS.map(({ content, className }, index) => (
                          <CellWrapper key={index} className={className}>
                            <Content>{content}</Content>
                          </CellWrapper>
                        ))}
                      </InvoicesTableGrid>
                    </Wrapper>
                  )
                })}
              </OuterContentWrap>
            ),
          }))}
        />
      </TableWrapper>

      <WrappedInvoiceModal
        invoiceId={invoiceForModal}
        setInvoiceForModal={setInvoiceForModal}
      />
      {hasMore && (
        <Button
          loader={isInvoicesLoading ? 'dots' : undefined}
          disabled={isInvoicesLoading}
          variant="link"
          onClick={loadMore}
        >
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_BUTTON_MORE" />
        </Button>
      )}
    </Section>
  )
}

const mapStateToProps = (state: RootState) => ({
  items: selectInvoiceTableItems(state),
  itemsMeta: selectInvoicesMeta(state),
  hasMore: selectHasMore(state),
})

export default connect(mapStateToProps)(InvoiceTable)
