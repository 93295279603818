import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { dataKey as authDataKey, authReducer } from 'features/auth/modules/auth'
import {
  dataKey as assuranceLevelDataKey,
  assuranceLevelReducer,
} from 'features/auth/modules/assuranceLevel'
import {
  dataKey as authResetDataKey,
  authResetReducer,
} from 'features/auth/modules/resetPassword'
import {
  dataKey as createAccountDataKey,
  createAccountReducer,
} from 'features/auth/modules/createAccount'
import {
  addCardDataKey,
  addCardReducer,
} from 'features/payment-methods/modules/addCard'
import {
  dataKey as removeCardDataKey,
  removeCardReducer,
} from 'features/payment-methods/modules/removeCard'
import {
  dataKey as subscriptionsDataKey,
  subscriptionsReducer,
} from 'features/subscriptions/module/subscriptions'
import {
  dataKey as subscriptionsUpdateDataKey,
  subscriptionsUpdateReducer,
} from 'features/subscriptions/module/subscriptionsUpdate'
import {
  dataKey as productsDataKey,
  productsReducer,
} from 'features/subscriptions/module/products'
import {
  dataKey as profilePictureDataKey,
  profilePictureReducer,
} from 'features/profile-picture/module/profilePicture'
import { ReduxAction } from 'types/types'
import {
  dataKey as twoFaResetDataKey,
  twoFaResetReducer,
} from 'features/auth/modules/twoFaReset'
import {
  dataKey as authResendVerificationDataKey,
  authResendVerificationReducer,
} from 'features/auth/modules/resendVerification'
import {
  dataKey as rolesDataKey,
  rolesReducer,
} from 'features/early-access/module/roles'
import {
  dataKey as invoicesDataKey,
  invoicesReducer,
} from 'features/subscriptions/module/invoices'
import {
  dataKey as invoicesUpdateDataKey,
  invoicesUpdateReducer,
} from 'features/subscriptions/module/invoicesUpdate'
import {
  dataKey as invoicesUpcomingDataKey,
  invoicesUpcomingReducer,
} from 'features/subscriptions/module/invoicesUpcoming'
import {
  dataKey as deviceLteDataKey,
  deviceLteReducer,
} from 'features/subscriptions/module/deviceLte'
import {
  dataKey as deviceTalkDataKey,
  deviceTalkReducer,
} from 'features/subscriptions/module/deviceTalk'
import {
  dataKey as deviceTalkInstallsDataKey,
  deviceTalkInstallsReducer,
} from 'features/subscriptions/module/deviceTalkInstalls'
import {
  dataKey as deviceTalkCancelDataKey,
  deviceTalkCancelReducer,
} from 'features/subscriptions/module/deviceTalkCancel'
import { mfaDataKey, mfaReducer } from 'modules/mfa'
import { mfaSetupDataKey, mfaSetupReducer } from 'modules/mfaSetup'
import { mfaAddedDataKey, mfaAddedReducer } from 'modules/mfaAdded'
import {
  generateBackupDataKey,
  generateBackupReducer,
} from 'pages/security/components/BackupCodes/modules'
import {
  reauthMFADataKey,
  reauthMFAReducer,
} from 'features/auth/modules/reauth'
import {
  ncaCredentialsReducer,
  dataKey as ncaDataKey,
} from 'features/backups/module/nca'
import {
  dataKey as deviceBackupsDataKey,
  fetchBackupsReducer,
} from 'features/backups/module/backups'
import {
  removePushDataKey,
  removePushReducer,
} from 'pages/security/components/Push/modules/removePush'
import {
  removePendingPushDataKey,
  removePendingPushReducer,
} from 'pages/security/components/Push/modules/removePendingPush'
import {
  removePendingTOTPDataKey,
  removePendingTOTPReducer,
} from 'pages/security/components/TOTP/modules/removePendingTOTP'
import {
  dataKey as deleteAccountDataKey,
  deleteAccountReducer,
} from 'features/delete-account/module/deleteAccount'
import { devicesDataKey, devicesReducer } from 'features/devices/devices'
import {
  addressesDataKey,
  addressesReducer,
} from 'features/addresses/module/addresses'
import {
  activityDataKey,
  activityReducer,
} from 'pages/activity/module/activity'
import {
  dataKey as changeEmailDataKey,
  requestEmailVerificationReducer,
} from 'pages/security/changeEmail'
import {
  updateCardDataKey,
  updateCardReducer,
} from 'features/payment-methods/modules/updateCard'
import {
  customerDataKey,
  stripeCustomerReducer,
} from 'features/stripe/modules/stripeCustomer'
import {
  invoiceLinesDataKey,
  invoiceLinesReducer,
} from 'features/subscriptions/module/invoiceLines'
import {
  updateCustomerDataKey,
  updateStripeCustomerReducer,
} from 'features/stripe/modules/updateStripeCustomer'
import {
  createCustomerDataKey,
  createStripeCustomerReducer,
} from 'features/stripe/modules/createStripeCustomer'
import {
  deviceUmrInfoReducer,
  umrDataKey,
} from 'features/subscriptions/module/deviceUmr'
import {
  umrDevicesDataKey,
  umrDevicesReducer,
} from 'features/devices/umrDevices'
import {
  deviceSiteSupportInfoCancelReducer,
  siteSupportDataKeyCancel,
} from 'features/subscriptions/module/deviceSiteSupportCancel'
import {
  addTaxIdDataKey,
  addTaxIdReducer,
} from 'features/payment-methods/modules/taxId/addTaxId'
import {
  fetchTaxIdReducer,
  getTaxIdDataKey,
} from 'features/payment-methods/modules/taxId/getTaxId'
import {
  updateTaxIdDataKey,
  updateTaxIdReducer,
} from 'features/payment-methods/modules/taxId/updateTaxId'
import {
  removeTaxIdDataKey,
  removeTaxIdReducer,
} from 'features/payment-methods/modules/taxId/removeTaxId'
import {
  openTicketsDataKey,
  openTicketsReducer,
} from 'modules/zendesk/openUserTickets'
import {
  initiateEmailDataKey,
  initiateEmailReducer,
} from 'pages/security/components/Email/modules/initiateEmail'
import {
  removeMFAEmailDataKey,
  removeMFAEmailReducer,
} from 'pages/security/components/Email/modules/removeEmail'
import {
  removeTempMFAEmailDataKey,
  removeTempMFAEmailReducer,
} from 'pages/security/components/Email/modules/removeTempEmail'
import {
  sendEmailDataKey,
  sendEmailReducer,
} from 'pages/security/components/Email/modules/sendEmail'
import {
  verifyEmailDataKey,
  verifyEmailReducer,
} from 'pages/security/components/Email/modules/verifyEmail'
import {
  initiatePushDataKey,
  inititatePushReducer,
} from 'pages/security/components/Push/modules/initiatePush'
import {
  loginPushDataKey,
  loginPushReducer,
} from 'pages/security/components/Push/modules/loginPush'
import {
  verifyPushDataKey,
  verifyPushReducer,
} from 'pages/security/components/Push/modules/verifyPush'
import {
  initiateMFASMSDataKey,
  initiateMFASMSReducer,
} from 'pages/security/components/SMS/modules/initiateMFASMS'
import {
  removeMFASMSDataKey,
  removeMFASMSReducer,
} from 'pages/security/components/SMS/modules/removeMFASMS'
import {
  sendMFASMSDataKey,
  sendMFASMSReducer,
} from 'pages/security/components/SMS/modules/sendMFASMS'
import {
  verifyMFASMSDataKey,
  verifyMFASMSReducer,
} from 'pages/security/components/SMS/modules/verifyMFASMS'
import {
  initatePasskeyLoginReducer,
  initiatePasskeyLoginDataKey,
  passkeyLoginRequestOptionsDataKey,
  passkeyLoginRequestOptionsReducer,
  verifyLoginPasskeyDataKey,
  verifyPasskeyLoginReducer,
} from 'pages/security/components/Passkey/modules/loginPasskey'
import {
  removePasskeyDataKey,
  removePasskeyReducer,
} from 'pages/security/components/Passkey/modules/removePasskey'
import {
  initiatePasskeyDataKey,
  initiatePasskeyReducer,
  verifyPasskeyDataKey,
  verifyPasskeyReducer,
} from 'pages/security/components/Passkey/modules/setupPasskey'
import {
  initiateTOTPDataKey,
  inititateTOTPReducer,
} from 'pages/security/components/TOTP/modules/initiateTOTP'
import {
  removeTOTPDataKey,
  removeTOTPReducer,
} from 'pages/security/components/TOTP/modules/removeTOTP'
import {
  updateTOTPDataKey,
  updateTOTPReducer,
} from 'pages/security/components/TOTP/modules/updateTOTP'
import {
  verifyTOTPDataKey,
  verifyTOTPReducer,
} from 'pages/security/components/TOTP/modules/verifyTOTP'
import {
  statusApiDataKey,
  statusApiReducer,
} from 'features/status/modules/statusApi'
import {
  announcementsDataKey,
  announcementsReducer,
} from 'features/announcements/module/announcements'
import {
  editAnnouncementsDataKey,
  editAnnouncementsReducer,
} from 'features/announcements/module/editAnnouncements'

import {
  uiTaxIdDataKey,
  uiTaxIdsReducer,
} from 'features/subscriptions/module/uiTaxIds'
import {
  BusinessProfileReducer,
  businessProfiledDataKey,
} from 'features/subscriptions/module/businessProfile'
import {
  closedTicketsDataKey,
  closedTicketsReducer,
} from './zendesk/closedUserTickets'
import { ccdTicketsDataKey, ccdTicketsReducer } from './zendesk/ccdUserTickets'
import { sessionDataKey, sessionReducer } from './session'
import { dataKey as twoDataKey, twoFaReducer } from './twoFa'
import { dataKey as legalDataKey, legalReducer } from './legal'
import { dataKey as localeDataKey, localeReducer } from './locale'
import { dataKey as modalsDataKey, modalsReducer } from './modals'
import { dataKey as profileDataKey, profileReducer } from './profile'
import {
  loginIssueFeedbackDataKey,
  loginIssueFeedbackReducer,
} from 'features/auth/modules/loginIssueFeedback'
import {
  fetchPaymentMethodsDataKey,
  fetchPaymentMethodsReducer,
} from 'features/payment-methods/modules/fetchPaymentMethods'

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    [authDataKey]: persistReducer<ReturnType<typeof authReducer>, ReduxAction>(
      { key: authDataKey, whitelist: ['isAuthenticated'], storage },
      authReducer
    ),
    [statusApiDataKey]: statusApiReducer,
    [profileDataKey]: profileReducer,
    [profilePictureDataKey]: persistReducer<
      ReturnType<typeof profilePictureReducer>,
      ReduxAction
    >(
      {
        key: profilePictureDataKey,
        whitelist: ['data'],
        storage,
      },
      profilePictureReducer
    ),
    [modalsDataKey]: modalsReducer,
    [twoDataKey]: twoFaReducer,
    [localeDataKey]: persistReducer<
      ReturnType<typeof localeReducer>,
      ReduxAction
    >({ key: localeDataKey, whitelist: ['language'], storage }, localeReducer),
    [authResetDataKey]: authResetReducer,
    [authResendVerificationDataKey]: authResendVerificationReducer,
    [createAccountDataKey]: createAccountReducer,
    [legalDataKey]: legalReducer,
    [addCardDataKey]: addCardReducer,
    [removeCardDataKey]: removeCardReducer,
    [addTaxIdDataKey]: addTaxIdReducer,
    [getTaxIdDataKey]: fetchTaxIdReducer,
    [subscriptionsDataKey]: subscriptionsReducer,
    [productsDataKey]: productsReducer,
    [twoFaResetDataKey]: twoFaResetReducer,
    [rolesDataKey]: rolesReducer,
    [invoicesDataKey]: invoicesReducer,
    [invoiceLinesDataKey]: invoiceLinesReducer,
    [uiTaxIdDataKey]: uiTaxIdsReducer,
    [businessProfiledDataKey]: BusinessProfileReducer,
    [invoicesUpcomingDataKey]: invoicesUpcomingReducer,
    [deviceLteDataKey]: deviceLteReducer,
    [deviceTalkDataKey]: deviceTalkReducer,
    [deviceTalkInstallsDataKey]: deviceTalkInstallsReducer,
    [deviceTalkCancelDataKey]: deviceTalkCancelReducer,
    [subscriptionsUpdateDataKey]: subscriptionsUpdateReducer,
    [invoicesUpdateDataKey]: invoicesUpdateReducer,
    [mfaDataKey]: mfaReducer,
    [initiatePasskeyLoginDataKey]: initatePasskeyLoginReducer,
    [passkeyLoginRequestOptionsDataKey]: passkeyLoginRequestOptionsReducer,
    [verifyLoginPasskeyDataKey]: verifyPasskeyLoginReducer,
    [initiatePasskeyDataKey]: initiatePasskeyReducer,
    [verifyPasskeyDataKey]: verifyPasskeyReducer,
    [removePasskeyDataKey]: removePasskeyReducer,
    [initiateTOTPDataKey]: inititateTOTPReducer,
    [removeTOTPDataKey]: removeTOTPReducer,
    [removePendingTOTPDataKey]: removePendingTOTPReducer,
    [updateTOTPDataKey]: updateTOTPReducer,
    [verifyTOTPDataKey]: verifyTOTPReducer,
    [initiatePushDataKey]: inititatePushReducer,
    [verifyPushDataKey]: verifyPushReducer,
    [removePushDataKey]: removePushReducer,
    [removePendingPushDataKey]: removePendingPushReducer,
    [loginPushDataKey]: loginPushReducer,
    [mfaSetupDataKey]: mfaSetupReducer,
    [initiateMFASMSDataKey]: initiateMFASMSReducer,
    [verifyMFASMSDataKey]: verifyMFASMSReducer,
    [removeMFASMSDataKey]: removeMFASMSReducer,
    [sendMFASMSDataKey]: sendMFASMSReducer,
    [initiateEmailDataKey]: initiateEmailReducer,
    [verifyEmailDataKey]: verifyEmailReducer,
    [sendEmailDataKey]: sendEmailReducer,
    [generateBackupDataKey]: generateBackupReducer,
    [reauthMFADataKey]: reauthMFAReducer,
    [removeMFAEmailDataKey]: removeMFAEmailReducer,
    [removeTempMFAEmailDataKey]: removeTempMFAEmailReducer,
    [ncaDataKey]: ncaCredentialsReducer,
    [deviceBackupsDataKey]: fetchBackupsReducer,
    [assuranceLevelDataKey]: assuranceLevelReducer,
    [mfaAddedDataKey]: mfaAddedReducer,
    [deleteAccountDataKey]: deleteAccountReducer,
    [devicesDataKey]: devicesReducer,
    [addressesDataKey]: addressesReducer,
    [activityDataKey]: activityReducer,
    [sessionDataKey]: sessionReducer,
    [openTicketsDataKey]: openTicketsReducer,
    [closedTicketsDataKey]: closedTicketsReducer,
    [ccdTicketsDataKey]: ccdTicketsReducer,
    [changeEmailDataKey]: requestEmailVerificationReducer,
    [updateCardDataKey]: updateCardReducer,
    [updateTaxIdDataKey]: updateTaxIdReducer,
    [removeTaxIdDataKey]: removeTaxIdReducer,
    [customerDataKey]: stripeCustomerReducer,
    [updateCustomerDataKey]: updateStripeCustomerReducer,
    [createCustomerDataKey]: createStripeCustomerReducer,
    [umrDataKey]: deviceUmrInfoReducer,
    [umrDevicesDataKey]: umrDevicesReducer,
    [siteSupportDataKeyCancel]: deviceSiteSupportInfoCancelReducer,
    [announcementsDataKey]: announcementsReducer,
    [editAnnouncementsDataKey]: editAnnouncementsReducer,
    [loginIssueFeedbackDataKey]: loginIssueFeedbackReducer,
    [fetchPaymentMethodsDataKey]: fetchPaymentMethodsReducer,
  })

export default createRootReducer
